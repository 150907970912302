import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserRole } from '@app/types/roles';
import { Assets } from '../../../../assets/images/index';
import { AuditService } from '../../services/audit.service';
import { BodyScrollService } from '../../services/body-scroll.service';
import { Status } from '../../types/status';
import { DocumentNoteType } from '../../types/documentNote.type';
import { DocumentInspectorComponent } from '../shared/document-inspector/document-inspector.component';
import { LoadingService } from 'src/app/services/loading.service';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { DocumentInspectorStateService } from 'src/app/services/document-inspector-state.service';

@Component({
  selector: 'order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
  @Input() requestData: any = {};
  activeId: number = 1;
  activeTabIndex: number = 0;
  applicationData: any;
  divider = Assets.dividerTriangle;
  file = Assets.pdf;
  files: any;
  tableData: any;
  images = {
    checked: Assets.check,
    clock: Assets.clock,
    altImage: Assets.altImage,
    calendar: Assets.calenadar,
    checkMark: Assets.checkMark,
  };
  trainingOrganization: any;
  trainingProgram: any;
  generalCommitment: any;
  id: number;
  openModal: boolean = false;
  fileIndex: number = 0;
  showTrainingData: boolean = true;
  showPledge: boolean = false;
  imgUrl: string = '';
  openAdvisorModal: boolean = false;
  generalNotes: string = '';
  ApprovedByCommitteeSecrateryAndWaitingTechnicalAdvisor: number =
    Status.ApprovedByCommitteeSecretaryWaitingTechnicalExpert;
  rejectedByCommitteeSecratary: number =
    Status.ReturnedByCommitteeSecrataryToInternalAuditor;
  technicalAdvisorTrainingProgramDocuments: any[] = [];
  requestStatus: number;
  showSuccessModal: boolean = false;
  technicalAdvisorRoleId = UserRole.TechnicalAdvisor;
  internalAuditorRoleId = UserRole.InternalAuditor;
  committeeSecretaryRoleId = UserRole.CommitteeSecretary;
  showTechnicalAdvisorSection: boolean = false;
  modalText: string;
  allNotes: any;
  committeeSecrataryObservations: any;
  committeeSecrataryObservationsControl = new FormControl();
  committeeObservationsTextValue: string;
  applicationVersion: number;
  displayedColumns: string[] = ['notes', 'evaluation', 'standard'];
  fieldVisitData: Array<any>;
  applicationIsReviewedByTechnicalAdvisor: boolean = false;
  showCommitteeSecretaryObservationsNote: boolean = false;
  applicationIsReviewedByInternalAuditor: boolean = false;

  @ViewChild('inspectorComponent')
  inspectorComponent: DocumentInspectorComponent;

  isSubmitButtonDisabled: boolean = false;

  constructor(
    private authService: AuthService,
    private bodyScrollService: BodyScrollService,
    private auditService: AuditService,
    private router: Router,
    private loadingService: LoadingService,
    public documentInspectorStateService: DocumentInspectorStateService,
  ) {
    bodyScrollService.modalScrollEnabled.subscribe((enableScroll) => {
      if (enableScroll) {
        document.body.classList.remove('modal-open');
      } else {
        document.body.classList.add('modal-open');
      }
    });
  }
  isLoading: Observable<boolean> = this.loadingService.isLoading$();

  userIsCommitteeSecretary: boolean = this.authService.hasRole(
    UserRole.CommitteeSecretary,
  );

  userIsInternalAuditor: boolean = this.authService.hasRole(
    UserRole.InternalAuditor,
  );

  showObservations: boolean;

  userCanDownloadFiles: boolean =
    this.userIsCommitteeSecretary || this.userIsInternalAuditor;

  ngOnInit(): void {
    this.documentInspectorStateService.setTrainingProgramDocumentMapping(
      this.requestData.trainingProgramDocuments
        ?.trainingProgramDocumentMapping || [],
    );
    this.trainingOrganization = this.requestData.trainingOrganization;
    this.applicationVersion = this.trainingOrganization?.applicationVersion;
    this.trainingProgram = this.requestData.trainingProgram;
    this.generalCommitment = this.requestData.generalCommitment;
    this.allNotes =
      this.requestData?.trainingProgramDocuments?.trainingProgramDocumentMapping?.[
        this.documentInspectorStateService?.tabDataIndex
      ]?.programDocumentLookup?.programDocumentCriteriaMappings;

    this.committeeSecrataryObservations = this.generalNotesFilter(
      this.requestData.trainingProgramDocumentNote,
      UserRole.CommitteeSecretary,
      DocumentNoteType.Views,
    );
    if (this.committeeSecrataryObservations) {
      this.committeeSecrataryObservationsControl.setValue(
        this.committeeSecrataryObservations.note,
      );
      this.committeeObservationsTextValue =
        this.committeeSecrataryObservations.note;
    }

    this.updateTechnicalAdvisorTrainingProgramDocuments();

    this.getElecSignature();

    this.id = this.requestData.trainingOrganization.id;
    this.requestStatus =
      this.requestData.workFlowTrainingOrganization.trainingOrganizationStatusLookupId;
    this.applicationIsReviewedByInternalAuditor =
      this.requestStatus ==
      Status.ApprovedByTheInternalAuditorWaitingCommitteeSecretary;
    this.applicationIsReviewedByTechnicalAdvisor =
      this.requestStatus ===
      Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary;

    this.showCommitteeSecretaryObservationsNote =
      this.requestStatus !==
        Status.ApprovedByTheInternalAuditorWaitingCommitteeSecretary &&
      this.requestStatus !==
        Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary;

    const hasTechnicalAdvisorReviewedApplication =
      this.technicalAdvisorTrainingProgramDocuments.every((tab) => {
        return tab.documentFileHasBeenReviewes?.some(
          (review) => review?.user?.roleId === UserRole.TechnicalAdvisor,
        );
      });

    this.showTechnicalAdvisorSection =
      !this.applicationIsReviewedByInternalAuditor ||
      (this.applicationIsReviewedByInternalAuditor &&
        hasTechnicalAdvisorReviewedApplication);

    this.modalText = this.applicationIsReviewedByInternalAuditor
      ? 'تم إرسال الطلب إلى الخبير الفني بنجاح'
      : 'تم إرسال الطلب إلى أعضاء اللجنة بنجاح';

    this.fieldVisitData = this.requestData?.trainingOrganizationFieldVisitForms;

    this.showObservations =
      this.userIsCommitteeSecretary &&
      this.requestStatus ==
        Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary;
  }

  updateTechnicalAdvisorTrainingProgramDocuments() {
    this.technicalAdvisorTrainingProgramDocuments =
      this.documentInspectorStateService.trainingProgramDocumentMapping.reduce(
        (accumulator: any[], current: any, index: number) =>
          current.documentRoles.includes(UserRole.TechnicalAdvisor)
            ? [...accumulator, { ...current, index }]
            : accumulator,
        [],
      );
  }

  getElecSignature() {
    if (this.generalCommitment.generalCommitmentFile.file.id)
      this.auditService
        .getFile(this.generalCommitment.generalCommitmentFile.file.id)
        .subscribe({
          next: (data) => {
            this.imgUrl = data;
          },
          error: (error) => {
            console.log('error', error);
          },
        });
  }

  trainingProgramAndPledgeIconsClickHandler(id: number, activeTabView: number) {
    this.documentInspectorStateService.setTabDataIndex(id);
    this.inspectorComponent.activeTabIndex = activeTabView;
    this.bodyScrollService.toggleBodyScroll(false);
    this.handleSaveEvaluationData();
    this.openModal = true;
    this.showTrainingData = !id;
    this.showPledge = !!id;
  }

  onDialogHide(status: boolean) {
    this.openAdvisorModal = status;
    this.isSubmitButtonDisabled = false;
  }

  filterCriteriaMapping(arr: any[], roleId: number) {
    return arr.filter(
      (element) => element.documentCriteriaLookup.roleId === roleId,
    );
  }

  submitButtonTextHandler(status: number) {
    switch (status) {
      case Status.ApprovedByTheInternalAuditorWaitingCommitteeSecretary:
        return 'إرسال الطلب إلى الخبير الفني';

      case Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary:
        return 'إرسال الطلب للنقاش و التصويت';

      case Status.DiscussionAndVoting:
        return 'العودة إلى النقاش و التصويت';

      case Status.ApprovedByCommitteeSecretaryWaitingGeneralManager:
      case Status.RenewalRequestHasBeenAcceptedByCommitteeSecretaryToGeneralManager:
        return 'العودة إلى نموذج تصويت اللجنة';

      case Status.ApprovedByCommitteeHeadWaitingCommitteeSecretary:
        return 'العودة إلى تفاصيل التصويت';

      case Status.RejectedByCommitteeHeadToCommitteeSecretary:
      case Status.RenewalRequestHasBeenRejectedByCommitteeHeadToCommitteeSecretary:
        return 'العودة إلى تسبيب الرفض';

      default:
        return '';
    }
  }

  submitButtonClickHandler(status: number) {
    switch (status) {
      case Status.ApprovedByTheInternalAuditorWaitingCommitteeSecretary:
        this.isSubmitButtonDisabled = true;
        this.openAdvisorModal = true;
        break;

      case Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary:
      case Status.ReturnedByCommitteeSecrataryToInternalAuditor:
        {
          const returnToInternalAuditor =
            status == Status.ReturnedByCommitteeSecrataryToInternalAuditor;
          this.showSuccessModal = true;
          this.loadingService.startLoading();
          if (returnToInternalAuditor) {
            this.modalText = 'تم إعادة الطلب إلى المدقق الداخلي';
          }
          this.isSubmitButtonDisabled = true;
          const updatedStatus = returnToInternalAuditor
            ? Status.ReturnedByCommitteeSecrataryToInternalAuditor
            : Status.DiscussionAndVoting;
          this.auditService
            .updateApplicationStatus({
              HasNotes: !!this.committeeObservationsTextValue,
              TrainingOrganizationStatusEnum: updatedStatus,
              TrainingOrganizationId: this.id,
              TrainingProgramDocumentNotes: this.committeeObservationsTextValue
                ? [
                    {
                      id: this.committeeSecrataryObservations?.id ?? 0,
                      documentNoteTypeId: DocumentNoteType.Views,
                      note: this.committeeSecrataryObservationsControl.value,
                    },
                  ]
                : [
                    {
                      id: this.committeeSecrataryObservations?.id ?? 0,
                    },
                  ],
            })
            .subscribe({
              next: () => {
                this.loadingService.stopLoading();
              },
              error: (error) => {
                console.log('Error sending the data', error);
              },
            });
        }
        break;

      case Status.DiscussionAndVoting:
      case Status.ApprovedByCommitteeSecretaryWaitingGeneralManager:
      case Status.RenewalRequestHasBeenAcceptedByCommitteeSecretaryToGeneralManager:
      case Status.ApprovedByCommitteeHeadWaitingCommitteeSecretary:
      case Status.RejectedByCommitteeHeadToCommitteeSecretary:
      case Status.RenewalRequestHasBeenRejectedByCommitteeHeadToCommitteeSecretary:
        this.router.navigate(['/dashboard/voting', this.id]);
        break;

      default:
        break;
    }
  }

  get isReturnToInternalAuditorButtonVisible() {
    return (
      (this.applicationIsReviewedByInternalAuditor ||
        this.applicationIsReviewedByTechnicalAdvisor) &&
      this.userIsCommitteeSecretary
    );
  }

  documentNotesFilter(notes: any[], roleId: number) {
    return roleId === UserRole.InternalAuditor
      ? notes.filter(
          (note) =>
            note.user.roleId === UserRole.InternalAuditor ||
            (note.user.roleId === UserRole.CommitteeSecretary &&
              note.assignToRoleId === UserRole.InternalAuditor) ||
            note.user.roleId === UserRole.TrainingOrganization,
        )
      : notes.filter(
          (note) =>
            note.user.roleId === UserRole.TechnicalAdvisor ||
            (note.user.roleId === UserRole.CommitteeSecretary &&
              note.assignToRoleId === UserRole.TechnicalAdvisor) ||
            note.user.roleId === UserRole.TrainingOrganization,
        );
  }

  onShowTrainingDataChange(showTrainingData: boolean) {
    this.showTrainingData = showTrainingData;
  }

  onShowPledge(showPledge: boolean) {
    this.showPledge = showPledge;
  }

  closeModel() {
    this.showSuccessModal = false;
    this.router.navigate(['/dashboard']);
  }

  hideModal() {
    this.openModal = false;
    this.bodyScrollService.toggleBodyScroll(true);
    this.updateTechnicalAdvisorTrainingProgramDocuments();
  }

  onTabDataIndexValueChange(newValue: number) {
    this.documentInspectorStateService.setTabDataIndex(newValue);
    this.handleSaveEvaluationData();
  }

  handleSaveEvaluationData() {
    this.allNotes =
      this.documentInspectorStateService.trainingProgramDocumentMapping[
        this.documentInspectorStateService.tabDataIndex
      ].programDocumentLookup?.programDocumentCriteriaMappings;
  }

  viewFile(
    sectionIndex: number,
    currentFileIndex: number,
    activeTabView: number,
  ) {
    this.documentInspectorStateService.setTabDataIndex(sectionIndex);
    this.bodyScrollService.toggleBodyScroll(false);
    this.fileIndex = currentFileIndex;
    this.handleSaveEvaluationData();
    this.openModal = true;
    this.showTrainingData = false;
    this.showPledge = false;
    this.inspectorComponent.fileIndex = currentFileIndex;
    this.inspectorComponent.activeTabIndex = activeTabView;
    this.inspectorComponent.showTrainingData = false;
    this.inspectorComponent.showPledge = false;
    this.inspectorComponent.getFileUrl(sectionIndex, currentFileIndex);
  }

  generalNotesFilter(
    generalNotes: any[],
    roleId: number,
    documentType?: DocumentNoteType,
  ) {
    return generalNotes.find(
      (item) =>
        item.note &&
        item.version == this.applicationVersion &&
        item?.user?.roleId == roleId &&
        (documentType ? item.documentNoteTypeLookupId === documentType : true),
    );
  }
}
