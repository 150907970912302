import {
  Component,
  EventEmitter,
  Output,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Assets } from '@assets/images';
import { Observable } from 'rxjs';
import { LoadingService } from '@app/services/loading.service';
import { AuditService } from '@app/dashboard/services/audit.service';
import { PrimeNGConfig } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Roles } from '@app/dashboard/types/status';
import { ChangeDetectorRef } from '@angular/core';
import { LookupsTypeEnum } from '@app/dashboard/types/lookUps';
import {
  AddOrRemoveUserRole,
  GetUsersData,
} from '@app/dashboard/types/userManagement';
import { ManageUsersService } from '@app/dashboard/services/manage-users.service';
import { TableComponent } from '../shared/table/table.component';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss'],
})
export class ManageUsersComponent implements OnInit {
  @Output() addUserEvent = new EventEmitter<void>();
  @ViewChild('tableComponent') tableComponent!: TableComponent;
  @ViewChild('tableComponent2') tableComponent2!: TableComponent;

  internalUsersHeaders: any[] = [
    { name: 'اسم المستخدم', value: 'username' },
    { name: 'البريد الإلكتروني', value: 'email' },
    { name: 'صلاحيات المستخدم', value: 'roleAr' },
    { name: 'حالة الحساب', value: 'isActive' },
  ];
  externalUsersHeaders: any[] = [
    { name: 'اسم المستخدم', value: 'username' },
    { name: 'البريد الإلكتروني', value: 'email' },
    { name: ' اسم الجهة', value: 'organizationName' },
    { name: 'حالة الحساب', value: 'isActive' },
  ];

  formGroupForAddUser: FormGroup;
  formGroupForViewUser: FormGroup;
  status: string[] = [];
  internalTableData: any;
  externalTableData: any;
  showModal: boolean = false;
  userDetails: any;
  externalUserDetails: any;
  assignableUserRoles: any[] = [];
  userProfileVisible: boolean = false;
  externalUserProfileVisible: boolean = false;
  hasAdditionalRoles: boolean = false;
  userID: number;
  additionalRoleId: number;
  tableDataRoleType: 2;
  roles: any[] = [];

  banner = Assets.banner;
  activeTabIndex: number = 0;
  internalUsers: any[] = [];
  isLoading: Observable<boolean> = this.loadingService.isLoading$();

  successModalVisible: boolean = false;
  successModalMessage: string = '';

  isDialogOpen: boolean = false;
  isLoadingDialogOpen = false;
  isModalLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  successMessage: string = '';

  constructor(
    private auditService: AuditService,
    private loadingService: LoadingService,
    private primengConfig: PrimeNGConfig,
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    private manageUsersService: ManageUsersService,
  ) {
    this.formGroupForAddUser = this.fb.group({
      username: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      selectedRole: [null, Validators.required],
    });
    this.formGroupForViewUser = this.fb.group({
      selectedAssignableRole: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.loadingService.startLoading();
    this.fetchUsers();
    this.getFilterData(LookupsTypeEnum.RoleEnum, this.status);
    this.fetchRoles();
  }

  selectedTabUserType: any = GetUsersData.internalUsers;
  onTabChange(event: any) {
    this.activeTabIndex = event.index;
    if (this.activeTabIndex === 0) {
      this.selectedTabUserType = GetUsersData.internalUsers;
    } else {
      this.selectedTabUserType = GetUsersData.ExternalUsers;
    }
    this.fetchUsers();
  }

  viewAccountEventHandler(event: any) {
    if (this.activeTabIndex === 0) {
      this.handleViewAccount(event);
    } else if (this.activeTabIndex === 1) {
      this.handleViewExternalAccount(event);
    }
  }

  getFilterData(lookup: number, filterValue: string[]) {
    this.auditService.getFiltersData(lookup).subscribe({
      next: (data) => {
        filterValue.length = 0;
        Array.prototype.push.apply(filterValue, data.data);
      },
      error: (error) => {
        console.error('Error fetching data:', error);
      },
    });
  }
  fetchRoles() {
    this.auditService.getFiltersData(LookupsTypeEnum.RoleEnum).subscribe({
      next: (data) => {
        this.roles = data.data.map((role: any) => ({
          label: role.value,
          value: role.key,
        }));
      },
      error: (error) => {
        console.error('Error fetching roles:', error);
      },
    });
  }

  bodyInternalUser = {
    userRoleType: 0,
    pageCount: 1,
    pageSize: 10,
    organizationName: '',
    allowSearch: false,
    userRoleId: '',
    trainingOrganizationStatusId: Roles.Default,
  };

  bodyExternalUser = {
    userRoleType: 0,
    pageCount: 1,
    pageSize: 10,
    organizationName: '',
    allowSearch: false,
    userRoleId: '',
    trainingOrganizationStatusId: Roles.Default,
  };
  body: any | null;

  fetchUsers(): void {
    this.body =
      this.selectedTabUserType === 1
        ? this.bodyInternalUser
        : this.bodyExternalUser;
    this.body.userRoleType = this.selectedTabUserType;
    this.manageUsersService.getUsers(this.body).subscribe({
      next: (data) => {
        this.loadingService.stopLoading();

        if (this.selectedTabUserType === 1) {
          this.internalTableData = data.data;

          // Process each user’s roles if necessary
          if (this.internalTableData && this.internalTableData.data) {
            this.internalTableData.data.forEach((user: any) => {
              user.roleAr = user.userRoles
                ? user.userRoles.map((role: any) => role.roleAr)
                : null;
            });
          }
          if (this.tableComponent) {
            this.tableComponent.updateUserData(this.internalTableData.data);
          }
        } else {
          this.externalTableData = data.data;
          this.tableComponent2.updateUserData(this.externalTableData.data);
        }
      },
      error: (error) => {
        console.error('Error fetching users:', error);
        this.loadingService.stopLoading();
      },
    });
  }

  onAddNewUser() {
    this.showModal = true;
    this.formGroupForAddUser.get('username')?.setValue('');
    this.formGroupForAddUser.get('email')?.setValue('');
    this.formGroupForAddUser.get('selectedRole')?.setValue('');
    this.formGroupForAddUser.markAsUntouched(); // Resets all controls to untouched
    this.formGroupForAddUser.updateValueAndValidity(); // Optional: Recheck validity
  }

  closeModal() {
    this.showModal = false;
    this.userProfileVisible = false;
    this.externalUserProfileVisible = false;

    this.formGroupForAddUser.reset();
    this.formGroupForAddUser.markAsPristine();
    this.formGroupForAddUser.updateValueAndValidity();

    this.formGroupForViewUser.reset();
  }
  get selectedAssignableRole() {
    return this.formGroupForViewUser.get('selectedAssignableRole');
  }

  get username() {
    return this.formGroupForAddUser.get('username');
  }

  get email() {
    return this.formGroupForAddUser.get('email');
  }

  get selectedRole() {
    return this.formGroupForAddUser.get('selectedRole');
  }
  saveUser() {
    if (!this.formGroupForAddUser.valid) {
      this.formGroupForAddUser.markAllAsTouched();
    } else {
      const username = this.formGroupForAddUser.get('username')?.value;
      const email = this.formGroupForAddUser.get('email')?.value;
      const roleId = this.formGroupForAddUser.get('selectedRole')?.value;

      const requestBody = {
        username: username,
        email: email,
        roleId: roleId,
      };

      this.isLoadingDialogOpen = true;
      this.isModalLoading.next(true);

      this.manageUsersService.addNewInternalUser(requestBody).subscribe({
        next: (response) => {
          this.isLoadingDialogOpen = false;
          this.isModalLoading.next(false);
          if (response.errorCode === 0) {
            this.closeModal();
            this.successMessage = 'تم إضافة المستخدم بنجاح';

            this.isDialogOpen = true;
            this.fetchUsers();

            setTimeout(() => {
              this.isDialogOpen = false;
            }, 3000);
          } else if (response.errorCode === 13) {
            this.isModalLoading.next(false);
            this.isLoadingDialogOpen = false;
            this.formGroupForAddUser
              .get('email')
              ?.setErrors({ emailExists: true });
          } else {
            console.error('Error adding user:', response.errorMessage);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.isLoadingDialogOpen = false;
          console.error('HTTP Error adding user:', error);
        },
      });
    }
  }

  getUserList(data: any) {
    if (this.selectedTabUserType === 1) this.bodyInternalUser = data;
    else this.bodyExternalUser = data;

    this.fetchUsers();
  }
  handleViewAccount(accountId: number) {
    this.userID = accountId;
    this.manageUsersService.getInternalAccountDetails(accountId).subscribe({
      next: (response) => {
        if (response.errorCode === 0) {
          this.userDetails = response.data;
          this.assignableUserRoles = response.data.assignableUserRoles;
          this.userProfileVisible = true; // Open the modal with user details

          this.hasAdditionalRoles =
            this.userDetails?.userRoles &&
            this.userDetails.userRoles.length > 1;

          if (this.hasAdditionalRoles) {
            this.additionalRoleId = this.userDetails.userRoles[1].id;
          } else {
            this.additionalRoleId = null; // Clear additionalRoleId if no additional role
          }
        } else {
          console.error('Error fetching user details:', response.errorMessage);
        }
      },
      error: (error) => {
        console.error('Error fetching account details:', error);
      },
    });
  }

  handleViewExternalAccount(accountId: number) {
    this.userID = accountId;
    this.manageUsersService.getexternalAccountDetails(accountId).subscribe({
      next: (response) => {
        if (response.errorCode === 0) {
          this.externalUserDetails = response.data;
          this.externalUserProfileVisible = true;
        } else {
          console.error('Error fetching user details:', response.errorMessage);
        }
      },
      error: (error) => {
        console.error('Error fetching account details:', error);
      },
    });
  }
  handleDeactivateAccount(accountId: number) {
    this.manageUsersService.activeOrDeactiveAccount(accountId).subscribe({
      next: (response) => {
        if (response.errorCode === 0) {
          this.fetchUsers();
        }
      },
    });
  }

  saveUserChanges() {
    const userId = this.userID;
    const roleId = this.formGroupForViewUser.get(
      'selectedAssignableRole',
    )?.value;

    if (roleId && userId) {
      const requestBody = {
        userId: userId,
        roleId: roleId,
        operation: AddOrRemoveUserRole.AssignNewRole,
      };

      this.isLoadingDialogOpen = true;
      this.isModalLoading.next(true);

      this.manageUsersService.assignOrRemoveUserRole(requestBody).subscribe({
        next: (response) => {
          this.isModalLoading.next(false);
          this.isLoadingDialogOpen = false;

          if (response.errorCode === 0) {
            this.isDialogOpen = true;
            this.successMessage = 'تم إسناد الصلاحية بنجاح';
            this.handleViewAccount(this.userID);

            this.hasAdditionalRoles = true;
            this.userDetails.additionalAssignedRoles = [
              ...(this.userDetails.additionalAssignedRoles || []),
              roleId,
            ];

            this.fetchUsers();
          }
        },
        error: (error) => {
          this.isLoadingDialogOpen = true;
          console.error('Error assigning role:', error);
        },
      });
    }
  }

  removeUserRole(roleId: number) {
    const userId = this.userID;
    roleId = this.additionalRoleId;

    const requestBody = {
      userId: userId,
      roleId: roleId,
      operation: AddOrRemoveUserRole.RemoveRole,
    };

    this.isLoadingDialogOpen = true;
    this.isModalLoading.next(true);

    this.manageUsersService.assignOrRemoveUserRole(requestBody).subscribe({
      next: (response) => {
        this.isModalLoading.next(false);

        this.isLoadingDialogOpen = false;

        if (response.errorCode === 0) {
          this.successMessage = 'تم إلغاء الإسناد بنجاح';
          this.isDialogOpen = true;
          this.fetchUsers();
          this.userDetails.userRoles = this.userDetails.userRoles.filter(
            (role: any) => role.id !== roleId,
          );

          this.hasAdditionalRoles = this.userDetails.userRoles.length > 1;

          if (!this.hasAdditionalRoles) {
            this.additionalRoleId = null;
          }
          this.fetchUsers();
        } else {
          console.error('Error removing role:', response.errorMessage);
        }
      },
      error: (error) => {
        console.error('Error removing role:', error);
      },
    });
  }

  showSuccessModal(message: string) {
    this.successModalMessage = message;
    this.successModalVisible = true;
  }
}
