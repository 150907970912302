import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BodyScrollService } from '@app/dashboard/services/body-scroll.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AuditService } from 'src/app/dashboard/services/audit.service';
import { Status } from 'src/app/dashboard/types/status';
import { AuthService } from 'src/app/services/auth.service';
import { DocumentInspectorStateService } from 'src/app/services/document-inspector-state.service';
import { LoadingService } from 'src/app/services/loading.service';
import { UserRole } from 'src/app/types/roles';

@Component({
  selector: 'app-document-inspector',
  templateUrl: './document-inspector.component.html',
  styleUrls: ['./document-inspector.component.scss'],
  providers: [LoadingService],
})
export class DocumentInspectorComponent {
  @Input() openModal: boolean;
  @Input() fileIndex: number;
  @Input() criteriaIndex: number = 0;
  @Input() isCheckbox: boolean = false; // Update the type as per your data structure
  @Input() checkboxOptions: string[] = []; // Update the type as per your data structure
  @Input() customClass: string;
  @Input() generalCommitment: any;
  @Input() criteriaLookup: any;
  @Input() requestStatus!: number;
  @Input() allNotes: any;
  @Input() trainingOrganizationId: any;
  @Input() trainingProgramDocumentsId: number;
  @Input() fileURL: string;
  @Input() oldFileUrl: string;
  fileUrlSubscription: Subscription;
  oldFileUrlSubscription: Subscription;
  @Input() updateRequestNumber: number;

  @Output() showTrainingDataChange = new EventEmitter<boolean>(); // Update the type as per your data structure
  @Output() showPledgeChange = new EventEmitter<boolean>(); // Update the type as per your data structure
  @Output() closeModal = new EventEmitter<void>();
  @Output() showTabDataIndexValueChanged = new EventEmitter<any>();

  showTrainingData: boolean = true; // Update the type as per your data structure
  showPledge: boolean = false;
  criteriaListIsOpen: boolean = false;
  criteriaAddedSuccessfully: boolean = false;
  selectedListItem: string = '';
  criteriaList: any = [];
  evaluationForms: FormGroup[] = [];
  addedCriteria: any = [];
  criteriaLastIndex: number;
  criteriaKey: number = 0;
  saveCriteriasSuccessfully: boolean = false;
  activeTabIndex: number = 0;
  showModal = false;
  userRole: number;
  zoomLevel: number | string;

  constructor(
    private auditService: AuditService,
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private authService: AuthService,
    private route: ActivatedRoute,
    public documentInspectorStateService: DocumentInspectorStateService,
    public bodyScrollService: BodyScrollService,
  ) {}

  userIsInternalAuditor: boolean = this.authService.hasRole(
    UserRole.InternalAuditor,
  );

  userIsTechnicalAdvisor: boolean = this.authService.hasRole(
    UserRole.TechnicalAdvisor,
  );

  userIsCommitteeSecretary: boolean = this.authService.hasRole(
    UserRole.CommitteeSecretary,
  );

  userIsCommitteeMember: boolean = this.authService.hasRole(
    UserRole.CommitteeMember,
  );

  userIsCommitteeHead: boolean = this.authService.hasRole(
    UserRole.CommitteeHead,
  );

  userIsGeneralManager: boolean = this.authService.hasRole(
    UserRole.GeneralManager,
  );

  userIsTrainingOrg: boolean = this.authService.hasRole(
    UserRole.TrainingOrganization,
  );

  showCriteriaNotesField: boolean;

  technicalAdvisorRoleId = UserRole.TechnicalAdvisor;
  internalAuditorRoleId = UserRole.InternalAuditor;

  isLoading: Observable<boolean> = this.loadingService.isLoading$();
  isSavingAllCriteria: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );
  startIndex: number = 0;
  fileStartIndex: number = 0;
  pageSize: number = 4;
  currentPage: number = 1;
  applicationIsReviewedByTechnicalAdvisor: boolean;

  saveEvaluationIsClicked: boolean = false;
  showValidationMessage: boolean = false;
  savingEvaluation: boolean = false;
  showAutoSaveModal: boolean = false;

  closeInspector() {
    if (
      this.showCriteriaNotesField &&
      (this.userIsInternalAuditor || this.userIsTechnicalAdvisor)
    )
      this.showAutoSaveModal = true;
    else this.closeAutoSaveModal();
  }

  closeAutoSaveModal() {
    this.showAutoSaveModal = false;
    this.closeModal.emit();
    this.fileIndex = 0;
    this.criteriaListIsOpen = false;
  }

  closeInfoModal() {
    this.showModal = false;
    this.bodyScrollService.toggleBodyScroll(false);
  }

  saveAllEvaluations() {
    this.saveEvaluation(true);
  }

  ngOnInit() {
    this.userRole = this.authService.getUserRole();
    this.showCriteriaNotesField =
      (this.userIsInternalAuditor &&
        (this.requestStatus === Status.NewOrderWaitingInternalAuditor ||
          this.requestStatus ===
            Status.RenewalRequestFromTrainingOrganizationToInternalAuditor ||
          this.requestStatus === Status.AddressedByTrainingOrganization ||
          this.requestStatus ===
            Status.ReturnedByCommitteeSecrataryToInternalAuditor ||
          this.requestStatus ===
            Status.ReturnedByCommitteeSecrataryAfterTechnicalAdvisor ||
          this.requestStatus ===
            Status.ReturnedByTechnicalAdvisorToInternalAuditor ||
          this.requestStatus ===
            Status.ReturnedByCommitteeSecretaryToInternalAuditorAfterFormulationNote)) ||
      (this.userIsTechnicalAdvisor &&
        (this.requestStatus ===
          Status.ApprovedByCommitteeSecretaryWaitingTechnicalExpert ||
          this.requestStatus ===
            Status.ReturnedByCommitteeSecretaryToTechnicalAdvisorAfterFormulationNote)) ||
      (this.userIsCommitteeSecretary &&
        this.requestStatus <
          Status.ApprovedByCommitteeHeadWaitingCommitteeSecretary);

    this.getCriteria();
    this.documentInspectorStateService.trainingProgramDocumentMapping?.forEach(
      (section, tabIndex) => {
        const controls = {};
        this.criteriaList[tabIndex]?.map((criteria, criteriaIndex) => {
          controls[criteria?.programDocumentCriteriaMappingsId] = this.fb.group(
            {
              checkbox: [criteria?.isPassed],
              note: [''],
            },
            {
              validator: !this.userIsTrainingOrg
                ? this.checkboxNoteValidator(tabIndex, criteriaIndex)
                : '',
            },
          );
        });

        const form = this.fb.group({
          criteriaItems: this.fb.group(controls),
          otherNotes: [''], // Added initialization for otherNotes
          fileHasBeenReviewed:
            (section?.documentFileHasBeenReviewes?.length &&
              section?.documentFileHasBeenReviewes?.find((item: any) =>
                this.authService.getUserRole() === UserRole.CommitteeSecretary
                  ? true
                  : this.authService.getUserRole() === UserRole.InternalAuditor
                    ? item?.user?.roleId &&
                      item?.user?.roleId === UserRole.InternalAuditor
                    : item?.user?.roleId &&
                      item?.user?.roleId === UserRole.TechnicalAdvisor,
              )?.fileHasBeenReviewed) ||
            false,
        });
        this.evaluationForms?.push(form);
      },
    );
    this.applicationIsReviewedByTechnicalAdvisor =
      this.requestStatus &&
      this.requestStatus >=
        Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary &&
      this.requestStatus !=
        Status.ReturnedByCommitteeSecrataryToInternalAuditor;
  }

  getVisibleItems(): any[] {
    this.startIndex =
      (this.documentInspectorStateService?.tabDataIndex + 1) % this.pageSize
        ? Math.floor(
            (this.documentInspectorStateService?.tabDataIndex + 1) /
              this.pageSize,
          ) * this.pageSize
        : Math.floor(
            this.documentInspectorStateService?.tabDataIndex / this.pageSize,
          ) * this.pageSize;

    return this.documentInspectorStateService.trainingProgramDocumentMapping?.slice(
      this.startIndex,
      this.startIndex + this.pageSize,
    );
  }

  showTabDataIndexChange(newValue: number) {
    this.documentInspectorStateService.setTabDataIndex(newValue);
    // Emit the changed value to the parent component
    this.showTabDataIndexValueChanged.emit(newValue);
  }

  getFilesPaginationItems(): any[] {
    this.fileStartIndex =
      (this.fileIndex + 1) % this.pageSize
        ? Math.floor((this.fileIndex + 1) / this.pageSize) * this.pageSize
        : Math.floor(this.fileIndex / this.pageSize) * this.pageSize;

    return this.documentInspectorStateService.trainingProgramDocumentMapping[
      this.documentInspectorStateService?.tabDataIndex
    ]?.files?.slice(this.fileStartIndex, this.fileStartIndex + this.pageSize);
  }

  showPledgeButton(): boolean {
    const lastSetStartIndex =
      Math.floor(
        this.documentInspectorStateService.trainingProgramDocumentMapping
          ?.length / this.pageSize,
      ) * this.pageSize;
    return this.startIndex + this.pageSize === lastSetStartIndex;
  }

  hasNextPage() {
    const lastSetStartIndex =
      Math.ceil(this.startIndex + 1 / this.pageSize) * this.pageSize;

    return (
      lastSetStartIndex <
      this.documentInspectorStateService.trainingProgramDocumentMapping
        ?.length -
        1
    );
  }

  nextSection(): void {
    const lastSetStartIndex =
      Math.floor(
        this.documentInspectorStateService.trainingProgramDocumentMapping
          ?.length / this.pageSize,
      ) * this.pageSize;

    if (this.startIndex + this.pageSize < lastSetStartIndex + this.pageSize) {
      this.startIndex += this.pageSize;
    }
    this.onSectionIndexClick(this.startIndex);
  }

  previousPage(): void {
    if (this.startIndex >= this.pageSize) {
      this.startIndex -= this.pageSize;
    }
    this.onSectionIndexClick(this.startIndex + this.pageSize - 1);
  }

  getCriteria() {
    this.criteriaList =
      this.documentInspectorStateService.trainingProgramDocumentMapping?.map(
        (item) =>
          this.userIsInternalAuditor
            ? item?.programDocumentLookup?.programDocumentCriteriaMappings?.filter(
                (item: any) =>
                  item?.documentCriteriaLookup?.roleId ===
                  UserRole.InternalAuditor,
              )
            : this.userIsTechnicalAdvisor
              ? item?.programDocumentLookup?.programDocumentCriteriaMappings?.filter(
                  (item: any) =>
                    item?.documentCriteriaLookup?.roleId ===
                    UserRole.TechnicalAdvisor,
                )
              : item?.programDocumentLookup?.programDocumentCriteriaMappings,
      );

    this.criteriaLastIndex = this.criteriaList?.length;
    return this.criteriaList;
  }

  getNotes() {
    this.allNotes =
      this.documentInspectorStateService.trainingProgramDocumentMapping[
        this.documentInspectorStateService?.tabDataIndex
      ].programDocumentLookup.programDocumentCriteriaMappings;
    return this.allNotes;
  }

  criteriaDropdown(event: Event) {
    event.stopImmediatePropagation();
    this.criteriaListIsOpen = !this.criteriaListIsOpen;
  }

  showTrainingDataSection() {
    this.showTrainingData = true;
    this.documentInspectorStateService.setTabDataIndex(0);
    this.showTrainingDataChange.emit(this.showTrainingData);
    this.showPledge = false;
    this.showPledgeChange.emit(this.showPledge);
    this.showTabDataIndexChange(
      this.documentInspectorStateService?.tabDataIndex,
    );
  }

  showPledgeSection() {
    this.documentInspectorStateService.setTabDataIndex(
      this.documentInspectorStateService.trainingProgramDocumentMapping
        ?.length - 1,
    );
    this.showTabDataIndexChange(
      this.documentInspectorStateService?.tabDataIndex,
    );
    this.showTrainingData = false;
    this.showTrainingDataChange.emit(this.showTrainingData);
    this.showPledge = true;
    this.showPledgeChange.emit(this.showPledge);
  }

  selectItem(item: any): void {
    const tabIndex = this.documentInspectorStateService.tabDataIndex;

    const exists = this.criteriaList[tabIndex]?.some(
      (element: any) =>
        element.documentCriteriaLookup.documentCriteriaLookupId === item.key,
    );

    if (!exists) {
      this.criteriaListIsOpen = false;
      this.criteriaAddedSuccessfully = true;
      const requestBody = this.prepareNewCriteriaBodyRequest(
        item.key,
        tabIndex,
      );
      this.submitCreateNewCriteria(requestBody, tabIndex);
    }
  }

  prepareNewCriteriaBodyRequest(criteriaId: number, tabIndex: number) {
    const trainingOrgId = this.route.snapshot.params['id'];

    const programDocumentCriteriaMappings = this.criteriaList[tabIndex].map(
      (criteria: any) => ({
        programDocumentCriteriaMappingId:
          criteria.programDocumentCriteriaMappingId || 0,
        isPassed: criteria.isPassed,
        documentCriteriaLookupId:
          criteria.documentCriteriaLookup.documentCriteriaLookupId,
        criteriaNotes: criteria.documentCriteriaLookup.criteriaNotes.map(
          (note: any) => ({
            id: note.id,
            note: note.name,
          }),
        ),
      }),
    );

    programDocumentCriteriaMappings.push({
      programDocumentCriteriaMappingId: 0,
      isPassed: null,
      documentCriteriaLookupId: criteriaId,
      criteriaNotes: [],
    });

    return {
      trainingProgramDocumentMappingId:
        this.documentInspectorStateService.trainingProgramDocumentMapping?.[
          this.documentInspectorStateService?.tabDataIndex
        ].trainingProgramDocumentMappingId || 0,
      programDocumentCriteriaMappings,
      trainingOrganizatinId: trainingOrgId,
      documentNotes: [],
      fileHasBeenReviewes: [{ id: 0, fileHasBeenReviewed: true }],
      trainingProgramDocumentsId: this.trainingProgramDocumentsId,
    };
  }

  viewFile(index: number) {
    this.oldFileUrl = '';
    this.fileURL = '';
    this.fileIndex = this.fileStartIndex + index;
    const activeTabIndex = this.documentInspectorStateService.tabDataIndex;
    const archivedFiles =
      this.documentInspectorStateService.trainingProgramDocumentMapping[
        activeTabIndex
      ]?.files[this.fileIndex]?.archivedFiles;
    const lastArchivedFile = archivedFiles[archivedFiles.length - 1];

    if (this.userIsTrainingOrg) {
      if (archivedFiles.length === 1) {
        this.getFileUrl(activeTabIndex, this.fileIndex);
      } else if (this.updateRequestNumber > lastArchivedFile.fileVersion) {
        this.getFileUrl(activeTabIndex, this.fileIndex);
      } else if (this.updateRequestNumber == lastArchivedFile.fileVersion) {
        this.getOldFileUrl(activeTabIndex, this.fileIndex, true);
        this.getFileUrl(activeTabIndex, this.fileIndex, false, true);
      }
    } else if (
      (this.userIsInternalAuditor || this.userIsTechnicalAdvisor) &&
      archivedFiles.length > 1
    ) {
      this.getFileUrl(activeTabIndex, this.fileIndex, false, true);
      this.getOldFileUrl(activeTabIndex, this.fileIndex, true);
    } else {
      this.getFileUrl(activeTabIndex, this.fileIndex);
    }
  }

  onSectionIndexClick(index: number): void {
    this.loadingService.stopLoading();
    if (!index) this.showTrainingDataSection();
    else {
      this.documentInspectorStateService.setTabDataIndex(index);
      this.showTrainingData = false;
      this.fileIndex = 0;
      this.oldFileUrl = '';
      this.fileURL = '';

      if (
        index ===
        this.documentInspectorStateService.trainingProgramDocumentMapping
          ?.length -
          1
      ) {
        this.showPledge = true;
        this.showPledgeChange.emit(this.showPledge);
        this.showTrainingDataChange.emit(this.showTrainingData);
      } else {
        this.viewFile(0);
        this.showTrainingDataChange.emit(this.showTrainingData);
        this.showPledge = false;
        this.showPledgeChange.emit(this.showPledge);
      }
      this.showTabDataIndexChange(index);
    }
  }

  getFileId(tabIndex: number, fileIndex: number) {
    return this.documentInspectorStateService.trainingProgramDocumentMapping?.[
      tabIndex
    ].files?.[fileIndex]?.archivedFiles?.at(-1)?.file?.id;
  }

  getOldFileId(tabIndex: number, fileIndex: number) {
    return this.documentInspectorStateService.trainingProgramDocumentMapping[
      tabIndex
    ].files[fileIndex]?.archivedFiles?.at(-2)?.file?.id;
  }

  getFileUrl(
    tabIndex: number,
    fileIndex: number,
    showValidation: boolean = false,
    waitOldFileUrl: boolean = false,
  ) {
    if (showValidation && this.showCriteriaNotesField)
      this.showValidationMessage = true;
    return this.getFile(
      this.getFileId(tabIndex, fileIndex),
      false,
      waitOldFileUrl,
    );
  }

  getOldFileUrl(
    tabIndex: number,
    fileIndex: number,
    waitFileUrl: boolean = false,
  ) {
    return this.getFile(
      this.getOldFileId(tabIndex, fileIndex),
      true,
      waitFileUrl,
    );
  }

  getFile(id: number, isOldFile: boolean = false, wait: boolean = false) {
    if (this.fileUrlSubscription && !isOldFile)
      this.fileUrlSubscription.unsubscribe();
    if (this.oldFileUrlSubscription && isOldFile)
      this.oldFileUrlSubscription.unsubscribe();
    if (id) {
      this.loadingService.startLoading();
      const subscription = this.auditService.getFile(id).subscribe({
        next: (data) => {
          // sync loading comparison files
          if (wait) {
            if (isOldFile) {
              if (this.fileURL) this.loadingService.stopLoading();
            } else if (this.oldFileUrl) this.loadingService.stopLoading();
          } else this.loadingService.stopLoading();

          if (isOldFile) this.oldFileUrl = data;
          else this.fileURL = data;
        },
        error: (error) => {
          console.log('error', error);
        },
      });
      if (isOldFile) this.oldFileUrlSubscription = subscription;
      else this.fileUrlSubscription = subscription;
    }
  }

  prevFile() {
    if (this.fileIndex > 0) {
      this.fileIndex--;
      this.getFilesPaginationItems();
      this.fileIndex -= this.fileStartIndex;
      this.viewFile(this.fileIndex);
    }
  }

  nextFile() {
    if (
      this.fileIndex <
      this.documentInspectorStateService.trainingProgramDocumentMapping?.[
        this.documentInspectorStateService?.tabDataIndex
      ]?.files?.length -
        1
    ) {
      this.fileIndex++;
      this.getFilesPaginationItems();
      this.fileIndex -= this.fileStartIndex;
      this.viewFile(this.fileIndex);
    }
  }

  saveEvaluation(allTabs: boolean = false): void {
    allTabs
      ? this.isSavingAllCriteria.next(true)
      : this.loadingService.startLoading();

    const formGroup =
      this.evaluationForms[this.documentInspectorStateService.tabDataIndex];

    if (!allTabs) {
      this.saveEvaluationIsClicked = true;
      this.showValidationMessage = true;
      formGroup.markAllAsTouched();
    }

    if (formGroup.valid || allTabs) {
      if (!allTabs) {
        this.savingEvaluation = true;
      }
      const body = this.constructRequestBody(allTabs);
      this.submitEvaluation(body, allTabs);
    } else {
      this.loadingService.stopLoading();
    }
  }

  private constructRequestBody(allTabs: boolean = false): any {
    const trainingOrgId = this.route.snapshot.params['id'];
    const requestBody = [];
    const formNotes = [];
    if (allTabs) {
      this.documentInspectorStateService.trainingProgramDocumentMapping.forEach(
        (document) => {
          formNotes.push(
            document.programDocumentLookup.programDocumentCriteriaMappings,
          );
        },
      );
    } else {
      formNotes.push(
        this.documentInspectorStateService.trainingProgramDocumentMapping[
          this.documentInspectorStateService?.tabDataIndex
        ].programDocumentLookup.programDocumentCriteriaMappings,
      );
    }
    const evaluation = allTabs
      ? this.evaluationForms
      : [
          this.evaluationForms?.[
            this.documentInspectorStateService?.tabDataIndex
          ],
        ];
    evaluation.forEach((tabs, tabIndex) => {
      const formNotesTabIndex = allTabs
        ? tabIndex
        : this.documentInspectorStateService.tabDataIndex;

      const criteriaItems = tabs?.value?.criteriaItems;

      const programDocumentCriteriaMappings = Object.entries(
        criteriaItems as { [key: string]: any },
      ).map(([key, value], index) => {
        const notes =
          this.filterCriteriaById(
            formNotes[tabIndex],
            key,
          )?.[0]?.documentCriteriaLookup?.criteriaNotes?.map((note) => ({
            id: note?.id,
            note: note?.name,
          })) || [];

        const additionalNote = value?.note ? [{ id: 0, note: value.note }] : [];

        const isPassedValue =
          this.userIsTrainingOrg || this.userIsCommitteeSecretary
            ? value?.checkbox
            : !notes.length &&
                !additionalNote.length &&
                !value?.checkbox &&
                allTabs
              ? null
              : value?.checkbox || false;

        return {
          programDocumentCriteriaMappingId: key || 0,
          isPassed: isPassedValue,
          documentCriteriaLookupId:
            this.criteriaList[formNotesTabIndex][index]?.documentCriteriaLookup
              .documentCriteriaLookupId,
          criteriaNotes: [...notes, ...additionalNote],
        };
      });

      const otherNotes =
        this.evaluationForms[formNotesTabIndex]?.value?.otherNotes;

      const documentNotes = otherNotes
        ? [
            {
              ...(this.userIsCommitteeSecretary
                ? { assignToRoleId: this.getCriteriaNoteRoleId() }
                : {}),
              note: otherNotes,
            },
          ]
        : [];

      const fileHasBeenReviewed = Object.keys(criteriaItems).length
        ? true
        : this.evaluationForms[formNotesTabIndex]?.value?.fileHasBeenReviewed;

      requestBody.push({
        trainingProgramDocumentMappingId:
          this.documentInspectorStateService.trainingProgramDocumentMapping?.[
            formNotesTabIndex
          ]?.trainingProgramDocumentMappingId || 0,
        programDocumentCriteriaMappings,
        trainingOrganizatinId: trainingOrgId,
        fileHasBeenReviewes: [
          { id: 0, fileHasBeenReviewed: fileHasBeenReviewed },
        ],
        documentNotes,
      });
    });
    return requestBody;
  }

  private submitCreateNewCriteria(body, tabIndex) {
    this.auditService.saveCriteria(body).subscribe({
      next: ({ data }) => {
        this.documentInspectorStateService.setTrainingProgramDocumentMapping(
          data?.trainingProgramDocumentMapping,
        );
        this.getCriteria();

        const criteriaItemsForm = this.evaluationForms[tabIndex].get(
          'criteriaItems',
        ) as FormGroup;

        const addedCriteriaIndex = this.criteriaList[tabIndex].length - 1; // passed to validator as a pointer to the added criteria
        const newCriteriaControl = this.fb.group(
          {
            checkbox: [],
            note: [''],
          },
          {
            validator: this.checkboxNoteValidator(tabIndex, addedCriteriaIndex),
          },
        );

        criteriaItemsForm.addControl(
          `${
            data?.trainingProgramDocumentMapping[
              this.documentInspectorStateService?.tabDataIndex
            ].programDocumentLookup.programDocumentCriteriaMappings.at(-1)
              .programDocumentCriteriaMappingsId
          }`,
          newCriteriaControl,
        );

        this.getNotes();
      },
      complete: () => this.loadingService.stopLoading(),
    });
  }

  private submitEvaluation(body: any, allTabs: boolean = false): void {
    this.auditService.saveAllEvaluations(body).subscribe({
      next: ({ data }) => {
        this.documentInspectorStateService.setTrainingProgramDocumentMapping(
          data?.trainingProgramDocumentMapping,
        );

        this.getCriteria();
        this.handleSuccess(allTabs);
      },
      complete: () => {
        if (allTabs) {
          this.closeModal.emit();
          this.fileIndex = 0;
          this.criteriaListIsOpen = false;
          this.showAutoSaveModal = false;
          this.isSavingAllCriteria.next(false);
        } else this.loadingService.stopLoading();
      },
    });
  }

  private handleSuccess(allTabs: boolean = false): void {
    this.getNotes();
    this.saveCriteriasSuccessfully = true;
    this.postEvaluationCleanup(allTabs);
  }

  private postEvaluationCleanup(allTabs: boolean = false): void {
    this.saveEvaluationIsClicked = false;
    this.showValidationMessage = false;
    const forms = !allTabs
      ? [this.evaluationForms[this.documentInspectorStateService.tabDataIndex]]
      : this.evaluationForms;
    forms.forEach((form) => {
      form.get('otherNotes').patchValue('');

      const criteriaItemsForm = form.get('criteriaItems') as FormGroup;

      Object.keys(criteriaItemsForm.controls).forEach((key) => {
        const controlGroup = criteriaItemsForm.get(key) as FormGroup;
        controlGroup.patchValue({
          note: '',
        });
      });
    });
  }

  checkboxNoteValidator(tabDataIndex, criteriaIndex): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      const checkbox = group.get('checkbox').value;
      const note = group.get('note').value;
      const criteria = this.criteriaList[tabDataIndex]?.[criteriaIndex];

      if (
        !checkbox &&
        !note &&
        !this.userIsCommitteeSecretary &&
        !criteria.documentCriteriaLookup.criteriaNotes.length
      ) {
        return {
          unfilledNote: 'هذا الحقل مطلوب',
        };
      }
      return null;
    };
  }

  filterCriteriaMapping(arr: any[], roleId = this.userRole) {
    return arr?.filter(
      (element) => element.documentCriteriaLookup?.roleId === roleId,
    );
  }

  filterCriteriaById(arr: any[], id) {
    return arr?.filter(
      (element) => element?.programDocumentCriteriaMappingsId == id,
    );
  }

  HideAdditionalCriteriasList() {
    this.criteriaListIsOpen = false;
  }

  filterCriteriasDropDownData() {
    return this.criteriaLookup?.filter(
      (item) =>
        item?.programDocuments?.key !==
        this.documentInspectorStateService.trainingProgramDocumentMapping[
          this.documentInspectorStateService?.tabDataIndex
        ]?.programDocumentLookup?.id,
    );
  }

  getCriteriaNoteRoleId() {
    if (
      this.requestStatus ===
        Status.ApprovedByTheInternalAuditorWaitingCommitteeSecretary &&
      !this.roleHasReviewedTab(this.technicalAdvisorRoleId)
    )
      return UserRole.InternalAuditor;

    if (
      this.requestStatus ===
        Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary ||
      this.roleHasReviewedTab(this.technicalAdvisorRoleId)
    )
      return this.showCriteriasInTabs() && this.activeTabIndex === 1
        ? UserRole.TechnicalAdvisor
        : UserRole.InternalAuditor;

    return null;
  }

  showCriteriasInTabs() {
    const activeTabIndex = this.documentInspectorStateService.tabDataIndex;

    return (
      (this.applicationIsReviewedByTechnicalAdvisor ||
        this.roleHasReviewedTab(this.technicalAdvisorRoleId)) &&
      this.documentInspectorStateService.trainingProgramDocumentMapping[
        activeTabIndex
      ]?.documentRoles?.includes(UserRole.TechnicalAdvisor) &&
      (this.userIsCommitteeSecretary ||
        this.userIsCommitteeMember ||
        this.userIsCommitteeHead ||
        this.userIsGeneralManager)
    );
  }

  currentSectionHasPdfFiles() {
    return this.documentInspectorStateService.trainingProgramDocumentMapping[
      this.documentInspectorStateService?.tabDataIndex
    ]?.files?.length;
  }

  currentSectionHasPDFComparison() {
    return (
      this.oldFileUrl &&
      this.documentInspectorStateService.trainingProgramDocumentMapping?.[
        this.documentInspectorStateService?.tabDataIndex
      ]?.programDocumentLookup?.isSupportedFileComparison
    );
  }

  infoIconClickHandler() {
    this.showModal = true;
  }

  getRoleName() {
    if (this.authService.hasRole(UserRole.InternalAuditor)) {
      return 'المدقق الداخلي';
    } else if (this.authService.hasRole(UserRole.CommitteeSecretary)) {
      return 'امين اللجنة';
    } else return 'الخبير الفني';
  }

  roleHasReviewedTab(role: UserRole, tabDataIndex?: number) {
    return this.documentInspectorStateService.trainingProgramDocumentMapping?.[
      tabDataIndex ?? this.documentInspectorStateService.tabDataIndex
    ]?.documentFileHasBeenReviewes?.some(
      (review) => review?.user?.roleId === role && review?.fileHasBeenReviewed,
    );
  }

  isInputInvalid(programDocumentCriteriaMappingsId) {
    return (
      this.showValidationMessage &&
      this.evaluationForms[this.documentInspectorStateService.tabDataIndex].get(
        ['criteriaItems', programDocumentCriteriaMappingsId],
      ).errors?.['unfilledNote']
    );
  }

  canUserHighlight() {
    return (
      this.documentInspectorStateService.trainingProgramDocumentMapping?.[
        this.documentInspectorStateService.tabDataIndex
      ]?.programDocumentLookup?.isSupportedFileComparison &&
      (this.userIsInternalAuditor || this.userIsTechnicalAdvisor)
    );
  }

  filterFailedCriteria() {
    const activeTabIndex = this.documentInspectorStateService?.tabDataIndex;
    if (this.userIsTrainingOrg)
      return this.criteriaList[activeTabIndex]?.filter(
        (criteria) => !criteria?.isPassed && criteria?.isPassed !== null,
      );
    if (this.userIsCommitteeSecretary)
      return this.criteriaList[activeTabIndex]?.filter(
        (criteria) =>
          criteria?.isPassed !== null &&
          criteria?.documentCriteriaLookup?.roleId === UserRole.InternalAuditor,
      );

    return this.criteriaList[activeTabIndex];
  }

  hideModal() {
    this.saveCriteriasSuccessfully = false;
    this.saveEvaluationIsClicked = false;
    this.showValidationMessage = false;
    this.savingEvaluation = false;
    this.criteriaAddedSuccessfully = false;
    this.bodyScrollService.toggleBodyScroll(false);
  }
}
