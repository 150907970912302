import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { AuthService } from '@app/services/auth.service';
import { LoadingService } from '@app/services/loading.service';
import { ObjectToFormDataService } from '@app/services/object-to-form-data.service';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ManageUsersService {
  private apiUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private loadingService: LoadingService,
    private objectToFormDataConvertor: ObjectToFormDataService,
  ) {}

  getUsers(body: any): Observable<any> {
    const url = `${this.apiUrl}/api/SystemAdministrator/GetAccounts/userRoleType/${body.userRoleType || 1}`;
    let params = new HttpParams()
      .set('AllowSearch', body.allowSearch)
      .set('pageSize', body.pageSize)
      .set('pageCount', body.pageCount);

    if (body.searchKeyword) {
      params = params.set('SearchKeyword', body.searchKeyword);
    }
    if (body.trainingOrganizationStatusId) {
      params = params.set('Role', body.trainingOrganizationStatusId);
    }

    return this.http.get<any>(url, { params });
  }

  addNewInternalUser(requestBody: any): Observable<any> {
    return this.http.post(
      this.apiUrl + '/api/SystemAdministrator/AddNewInternalUser',
      requestBody,
      {
        headers: {
          'Content-Type': 'application/json', // Ensure you set this header
        },
      },
    );
  }
  getInternalAccountDetails(id: number): Observable<any> {
    return this.http.get<any>(
      this.apiUrl + `/api/SystemAdministrator/GetInternalAccountDetails/${id}`,
    );
  }

  getexternalAccountDetails(id: number): Observable<any> {
    return this.http.get<any>(
      this.apiUrl + `/api/SystemAdministrator/GetExternalAccountDetails/${id}`,
    );
  }

  assignOrRemoveUserRole(requestBody: any): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/api/SystemAdministrator/AssignOrRemoveUserRole`,
      requestBody,
    );
  }

  activeOrDeactiveAccount(userID: number): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/api/SystemAdministrator/ActivateAccount/${userID}`,
      {},
    );
  }
}
