import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-search-bar-audit',
  templateUrl: './search-bar-audit.component.html',
  styleUrls: ['./search-bar-audit.component.scss'],
})
export class SearchBarAuditComponent {
  date = new FormControl();
  filters: {
    searchQuery: string;
    status: string;
    startDate: Date;
    endDate: Date;
    applicationType: string;
    languageTypeId: string | number;
  } = {
    searchQuery: '',
    status: '',
    startDate: null,
    endDate: null,
    applicationType: '',
    languageTypeId: '',
  };

  @Input() options: string[];
  @Input() isFinancialAuditor: boolean = false;
  @Input() applicationTypeOptions: string[];
  @Input() isDateFilter: boolean = false;
  @Input() selectionMode: string;
  @Input() languageOptions: string[];
  @Input() receiptsTable: boolean = false;
  @Input() manageUserSearch: boolean = false;
  @Input() addUser: boolean = false;
  @Output() filtersChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() addUserEvent: EventEmitter<void> = new EventEmitter<void>();

  handleDateSelected(date: Date) {
    this.filters.startDate = date['startDate'].toLocaleDateString('en-GB');
    this.filters.endDate = date['endDate'].toLocaleDateString('en-GB');
    this.emitFilters();
  }

  onSearch() {
    this.emitFilters();
  }

  receiveMessage(message: string) {
    this.filters.status = message;
    this.emitFilters();
  }

  getApplicationType(type: string) {
    this.filters.applicationType = type;
    this.emitFilters();
  }

  getProgramLanguage(language: any) {
    this.filters.languageTypeId = language;
    this.emitFilters();
  }

  private emitFilters() {
    this.filtersChanged.emit(this.filters);
  }

  addUserHandler() {
    this.addUserEvent.emit();
  }
}
