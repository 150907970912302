import {
  Component,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserRole } from '@app/types/roles';
import { AuditService } from '../../services/audit.service';
import { Status } from '../../types/status';
import { DocumentInspectorStateService } from 'src/app/services/document-inspector-state.service';
import { Breadcrumbs } from '@app/dashboard/types/breadcrumbs.type';

@Component({
  selector: 'app-application-details',
  templateUrl: './application-details.component.html',
  styleUrls: ['./application-details.component.scss'],
})
export class ApplicationDetailsComponent {
  @ViewChild('tabsContainer') tabsContainer: ElementRef;
  @Output() activeTabIndexChange = new EventEmitter<number>();
  id: number | string;
  activeTabIndex: number = 0;
  signatureImageURL: string = 'assets/images/electronicSignature.png';
  selectedFiles: File[] = [];
  openModal: boolean = false;
  fileIndex: number = 0;
  tabsData: tabsData[];
  fileURL: string = '';
  levels: Breadcrumbs[] = [
    {
      name: 'dashboard',
      path: '/dashboard',
    },
  ];

  requestData: any = {};
  requestStatus: number;
  showRequestDetails: boolean = false;
  userShouldNotSeeApplication: boolean = false;
  showRequestDetailsButton: boolean;
  showVotingForm: boolean;
  isGeneratedFormButtonVisible: boolean = false;
  isCommitteeSecretaryNoteLayout: boolean = false;

  constructor(
    private el: ElementRef,
    private route: ActivatedRoute,
    private authService: AuthService,
    private auditService: AuditService,
    private router: Router,
    private documentInspectorStateService: DocumentInspectorStateService,
  ) {}

  ngOnInit() {
    this.documentInspectorStateService.setTabDataIndex(0);
    this.route.params.subscribe((params) => {
      this.id = params['id'];

      this.levels.push({
        name: 'orderDetails',
        path: '',
        clickHandler: () => this.breadCrumbCLickHandler.call(this),
      });

      this.auditService.getApplicationData(this.id).subscribe((response) => {
        if (response.data) {
          this.requestData = response.data;
          this.documentInspectorStateService.setTrainingProgramDocumentMapping(
            response.data?.trainingProgramDocuments
              ?.trainingProgramDocumentMapping,
          );
          this.documentInspectorStateService.setTabDataIndex(0);
        } else this.router.navigate(['/error-page']);
        this.requestStatus =
          response?.data?.workFlowTrainingOrganization?.trainingOrganizationStatusLookupId;

        switch (this.requestStatus) {
          case Status.NewOrderWaitingInternalAuditor:
          case Status.RenewalRequestFromTrainingOrganizationToInternalAuditor:
          case Status.AddressedByTrainingOrganization:
          case Status.ReturnedByCommitteeSecrataryToInternalAuditor:
          case Status.ReturnedByCommitteeSecrataryAfterTechnicalAdvisor:
          case Status.ReturnedByTechnicalAdvisorToInternalAuditor:
          case Status.ReturnedByCommitteeSecretaryToInternalAuditorAfterFormulationNote:
            this.userShouldNotSeeApplication = !this.isInternalAuditor();
            break;
          case Status.ApprovedByCommitteeHeadWaitingCommitteeSecretary:
          case Status.RejectedByCommitteeHeadToCommitteeSecretary: // rejected
          case Status.RenewalRequestHasBeenRejectedByCommitteeHeadToCommitteeSecretary: //rejected
            this.userShouldNotSeeApplication = !this.isCommitteeSecretary();
            this.isGeneratedFormButtonVisible = true;
            break;
          case Status.ApprovedByTheInternalAuditorWaitingCommitteeSecretary:
          case Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary:
          case Status.ApprovedByTheFinancialAuditorWaitingCommitteeSecretary:
            this.userShouldNotSeeApplication = !this.isCommitteeSecretary();
            break;
          case Status.ApprovedByCommitteeSecretaryWaitingTechnicalExpert:
          case Status.ReturnedByCommitteeSecretaryToTechnicalAdvisorAfterFormulationNote:
            this.userShouldNotSeeApplication = !this.isTechnicalAdvisor();
            break;
          case Status.DiscussionAndVoting:
            this.userShouldNotSeeApplication =
              !this.isCommitteeHead() &&
              !this.isCommitteeMember() &&
              !this.isCommitteeSecretary();
            break;
          case Status.ApprovedByCommitteeSecretaryWaitingGeneralManager:
          case Status.RenewalRequestHasBeenAcceptedByCommitteeSecretaryToGeneralManager:
            this.userShouldNotSeeApplication = !this.isGeneralManager();
            this.isGeneratedFormButtonVisible = true;
            break;
          case Status.Draft:
          case Status.ApprovedByCommitteeSecretaryWaitingPayment:
          case Status.ThePaymentWasMadeWaitingFinancialAuditor:
          case Status.ApprovedByTheGeneralManagerAndTheCertificateHasBeenIssued:
          case Status.CertificateHasBeenRenewedByGeneralManagerToTrainingOrganization:
          case Status.ReturnedByInternalAuditorToTrainingOrganization:
          default:
            this.userShouldNotSeeApplication = true;
            break;
        }

        if (this.userShouldNotSeeApplication)
          this.router.navigate(['/error-page']);

        this.showRequestDetails =
          this.isCommitteeSecretary() ||
          ((this.isCommitteeHead() || this.isCommitteeMember()) &&
            this.requestStatus === Status.DiscussionAndVoting) ||
          (this.isGeneralManager() &&
            this.requestStatus >=
              Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary);
      });
    });
  }

  isInternalAuditor(): boolean {
    return this.authService.hasRole(UserRole.InternalAuditor);
  }

  isTechnicalAdvisor(): boolean {
    return this.authService.hasRole(UserRole.TechnicalAdvisor);
  }

  isCommitteeSecretary(): boolean {
    return this.authService.hasRole(UserRole.CommitteeSecretary);
  }

  isCommitteeMember(): boolean {
    return this.authService.hasRole(UserRole.CommitteeMember);
  }

  isCommitteeHead(): boolean {
    return this.authService.hasRole(UserRole.CommitteeHead);
  }

  isGeneralManager(): boolean {
    return this.authService.hasRole(UserRole.GeneralManager);
  }

  getFileSize(sizeInBytes: number): string {
    const kbSize = sizeInBytes / 1024;
    if (kbSize < 1024) {
      return kbSize.toFixed(2) + ' KB';
    } else {
      const mbSize = kbSize / 1024;

      return mbSize.toFixed(2) + ' MB';
    }
  }

  navigateToPreviousTab = (): void => {
    if (this.activeTabIndex > 0) {
      this.activeTabIndex--;
      this.scrollIntoView();
    }
  };

  navigateToSecretaryForm() {
    this.router.navigate([`/dashboard/secretary-form/${this.id}`]);
  }

  scrollIntoView() {
    const element = this.el.nativeElement; // If you are using ElementRef
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  hideModal(): void {
    this.openModal = false;
    this.fileURL = '';
  }

  hideRequestDetails(event: boolean) {
    this.showRequestDetails = event;
    this.showVotingForm = !event;
  }

  showCommitteeSecretaryNote(show: boolean) {
    if (show) {
      this.isCommitteeSecretaryNoteLayout = true;
      this.levels = [...this.levels, { name: 'ملاحظات الأمين', path: '' }];
    } else {
      this.isCommitteeSecretaryNoteLayout = false;
      this.levels.pop();
    }
  }

  breadCrumbCLickHandler() {
    if (this.isCommitteeSecretaryNoteLayout)
      this.showCommitteeSecretaryNote(false);
  }
}

export interface FileInputSetting {
  id: string;
  fileList: File[];
  text: string;
}

export interface tabData {
  icon: string;
  name: string;
  value: string;
  files: files[];
  rowName: string;
}

export interface files {
  name: string;
  size: number;
  file: string;
}

export interface tabsData {
  tabId: number;
  tabData: tabData[];
  rowTitle: string;
}
