export interface EvaluationsFile {
  technicalAdvisorEvaluationsFileId: number;
  version: number;
  file: File;
}

import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { UserRole } from '@app/types/roles';
import { AuditService } from '../../services/audit.service';
import { Status } from '../../types/status';
import { DocumentNoteType } from '../../types/documentNote.type';
import { ExpertApplicationService } from './expert-application.service';
import { DocumentInspectorComponent } from '../shared/document-inspector/document-inspector.component';
import { AuthService } from 'src/app/services/auth.service';
import { DocumentInspectorStateService } from 'src/app/services/document-inspector-state.service';
import { BodyScrollService } from '@app/dashboard/services/body-scroll.service';
import { LoadingService } from '@app/services/loading.service';
import { Observable } from 'rxjs';
import { filesAssets } from '@assets/files';

@Component({
  selector: 'expert-application-view',
  templateUrl: './expert-application-view.component.html',
  styleUrl: './expert-application-view.component.scss',
})
export class ExpertApplicationView {
  showModal: boolean = true;
  @Input() id: string | number;
  @Input() files: any[];
  @Input() requestData: any;

  allNotes: any;
  expertForm: FormGroup;
  fileURL: string = '';
  fileIndex: number = 0;
  criteriaLookup: any;
  showTrainingData: boolean = true;
  fieldVisitForm: FormGroup;
  technicalAdvisorEvaluationFiles: EvaluationsFile[] = [];
  tempTechnicalAdvisorEvaluationsFileId: number;
  isUploadEvaluationFileHidden: boolean;
  showSubmitModal: boolean = false;
  submittedSuccessfully: boolean = false;
  isSubmitButtonClicked: boolean = false;
  isFileTypeInvalid: boolean = false;
  requestStatus: number;
  updateRequestNumber: number;
  applicationVersion: number;
  isReturnToInternalAuditorBtnClicked: boolean = false;
  isLoading: Observable<boolean> = this.loadingService.isLoading$();
  pdfUrl: string;
  fieldVisitData: Array<any>;
  technicalAdvisorLastNote: any;
  generalNoteText: string;
  generalCommitment: any;
  showPledge: boolean = false;
  trainingOrganization: any;
  committeeSecretaryDiscussionNote: any;
  showCommitteeSecretaryNoteButton: boolean = false;
  noNotesHTML: string =
    '<h2 class="ql-direction-rtl ql-align-right">لا توجد ملاحظات</h2>';

  @Input() showCommitteeSecretaryNote!: boolean;
  @Output() showCommitteeSecretaryNoteChange = new EventEmitter<boolean>();

  @ViewChild('inspectorComponent')
  inspectorComponent: DocumentInspectorComponent;

  constructor(
    private formBuilder: FormBuilder,
    private auditService: AuditService,
    private submitService: ExpertApplicationService,
    private router: Router,
    private authService: AuthService,
    public documentInspectorStateService: DocumentInspectorStateService,
    private bodyScrollService: BodyScrollService,
    private loadingService: LoadingService,
  ) {
    bodyScrollService.modalScrollEnabled.subscribe((enableScroll) => {
      if (enableScroll) {
        document.body.classList.remove('modal-open');
      } else {
        document.body.classList.add('modal-open');
      }
    });
  }
  getTechnicalAdvisorLastGeneralNote(
    notes: Array<any>,
    applicationVersion: number,
  ) {
    return notes.find((note) => {
      return (
        note?.user?.roleId === UserRole.TechnicalAdvisor &&
        note?.version === applicationVersion
      );
    });
  }

  ngOnInit(): void {
    this.fetchCriteriaLookup();
    this.fieldVisitData = this.requestData?.trainingOrganizationFieldVisitForms;
    this.generalCommitment = this.requestData.generalCommitment;
    this.trainingOrganization = this.requestData.trainingOrganization;
    this.allNotes =
      this.requestData?.trainingProgramDocuments?.trainingProgramDocumentMapping?.[
        this.documentInspectorStateService?.tabDataIndex
      ]?.programDocumentLookup?.programDocumentCriteriaMappings;

    this.requestStatus =
      this.requestData?.workFlowTrainingOrganization?.trainingOrganizationStatusLookupId;

    if (
      this.requestStatus ===
      Status.ReturnedByCommitteeSecretaryToTechnicalAdvisorAfterFormulationNote
    )
      this.showModal = false;
    else this.bodyScrollService.toggleBodyScroll(false);

    this.updateRequestNumber =
      this.requestData?.trainingProgramDocuments?.updateRequestNumber;

    this.applicationVersion =
      this.requestData?.trainingOrganization?.applicationVersion;

    this.technicalAdvisorLastNote = this.getTechnicalAdvisorLastGeneralNote(
      this.requestData?.trainingProgramDocumentNote,
      this.applicationVersion,
    );

    this.committeeSecretaryDiscussionNote =
      this.requestData.committeeSecretaryNote;

    if (
      this.requestStatus ===
      Status.ReturnedByCommitteeSecretaryToTechnicalAdvisorAfterFormulationNote
    )
      this.showCommitteeSecretaryNoteButton = true;

    this.expertForm = this.formBuilder.group({
      needsFieldVisit: false,
      hasGeneralNotes: !!this.technicalAdvisorLastNote?.note,
      generalNotes: this.technicalAdvisorLastNote?.note,
    });
    this.generalNoteText = this.technicalAdvisorLastNote?.note;

    this.expertForm.get('hasGeneralNotes').valueChanges.subscribe((value) => {
      const generalNotesControl = this.expertForm.get('generalNotes');
      if (value) {
        generalNotesControl.addValidators(Validators.required);
        generalNotesControl.updateValueAndValidity();
      } else {
        generalNotesControl.clearValidators();
        generalNotesControl.updateValueAndValidity();
      }
    });

    this.initializeFieldVisitForm();

    this.documentInspectorStateService.setTrainingProgramDocumentMapping(
      this.requestData.trainingProgramDocuments?.trainingProgramDocumentMapping,
    );

    this.pdfUrl = document.baseURI + filesAssets.fieldVisitCriteria;

    this.technicalAdvisorEvaluationFiles =
      this.requestData.technicalAdvisorEvaluationsFiles.filter(
        (f) => f.version == this.applicationVersion,
      );

    this.isUploadEvaluationFileHidden =
      this.technicalAdvisorEvaluationFiles.length > 0;
  }

  initializeFieldVisitForm() {
    const fieldVisitCriteria: FormGroup[] = [];
    for (let i = 0; i < 5; i++) {
      fieldVisitCriteria[i] = this.formBuilder.group(
        {
          criteria: null,
          radio: null,
          note: null,
          files: [],
        },
        { validators: this.criteriaValidation() },
      );
    }

    this.fieldVisitForm = this.formBuilder.group({
      fieldVisitCriteria: new FormArray(fieldVisitCriteria),
      fieldVisitFiles: [],
      fieldVisitNotes: null,
    });
  }

  get fieldVisitCriteriaControlList() {
    return (this.fieldVisitForm.get('fieldVisitCriteria') as FormArray)
      ?.controls;
  }

  criteriaValidation(): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      const criteria = group.get('criteria');
      const note = group.get('note');
      const isAppicable = group.get('radio');
      const file = group.get('files');
      const errors: {
        missingFile?: boolean;
        missingNote?: boolean;
        criteriaRequired?: boolean;
        applicableRequired?: boolean;
      } = {};

      if (criteria.value) {
        if (isAppicable.value === null) return { applicableRequired: true };

        if (isAppicable.value === false) {
          if (!file.value?.length) errors.missingFile = true;
          if (!note.value) errors.missingNote = true;

          return errors;
        }
      }

      if (
        !criteria.value &&
        (isAppicable.value !== null || note.value || file.value?.length)
      ) {
        errors.criteriaRequired = true;

        if (isAppicable.value === false) {
          if (!file.value?.length) errors.missingFile = true;
          if (!note.value) errors.missingNote = true;
        }

        if (note.value && isAppicable.value === null)
          errors.applicableRequired = true;
        return errors;
      }

      return null;
    };
  }

  addCriteria() {
    (this.fieldVisitForm.get('fieldVisitCriteria') as FormArray).push(
      this.formBuilder.group(
        {
          criteria: null,
          radio: null,
          note: null,
          files: [],
        },
        { validators: this.criteriaValidation() },
      ),
    );
  }

  onFileUpload(event) {
    const fieldVisitFiles =
      this.fieldVisitForm.get('fieldVisitFiles').value || [];
    if (event.target.files.length) fieldVisitFiles.push(...event.target.files);
    this.fieldVisitForm.get('fieldVisitFiles').setValue(fieldVisitFiles);
  }

  onUploadTechnicalAdvisorEvaluationFile(event) {
    if (event.target.files[0].type != 'application/pdf') {
      this.isFileTypeInvalid = true;
      return;
    }

    this.isFileTypeInvalid = false;
    const file: EvaluationsFile = {
      technicalAdvisorEvaluationsFileId:
        this.tempTechnicalAdvisorEvaluationsFileId,
      version: this.applicationVersion,
      file: event.target.files[0],
    };
    this.technicalAdvisorEvaluationFiles.push(file);
    this.isUploadEvaluationFileHidden = true;
    this.tempTechnicalAdvisorEvaluationsFileId = 0;
  }

  onRemoveTechnicalAdvisorEvaluationFile(file) {
    this.technicalAdvisorEvaluationFiles =
      this.technicalAdvisorEvaluationFiles.filter(
        (f) =>
          f.technicalAdvisorEvaluationsFileId !=
          file.technicalAdvisorEvaluationsFileId,
      );
    this.isUploadEvaluationFileHidden = false;
    this.tempTechnicalAdvisorEvaluationsFileId =
      file.technicalAdvisorEvaluationsFileId;
  }

  removeFile(index: number) {
    const fieldVisitFiles = this.fieldVisitForm.get('fieldVisitFiles').value;
    fieldVisitFiles.splice(index, 1);
    this.fieldVisitForm.get('fieldVisitFiles').setValue(fieldVisitFiles);
  }

  onTabDataIndexValueChange(newValue: any) {
    this.documentInspectorStateService.setTabDataIndex(newValue);
    this.handleSaveEvaluationData();
  }

  hideModal(): void {
    this.showModal = false;
    this.fileURL = '';
    this.bodyScrollService.toggleBodyScroll(true);
  }

  hideCommitteeSecretaryNote() {
    this.showCommitteeSecretaryNote = false;
    this.showCommitteeSecretaryNoteChange.emit(false);
    document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  fetchCriteriaLookup() {
    this.auditService.getCriteriaLookup(UserRole.TechnicalAdvisor).subscribe({
      next: (data) => {
        this.criteriaLookup = data.data;
      },
      error: (error) => {
        console.log('Error Retrieving Criteria', error);
      },
    });
  }

  onShowTrainingDataChange(showTrainingData: boolean) {
    this.showTrainingData = showTrainingData;
  }

  hasCriteriaValue(): boolean {
    return this.fieldVisitCriteriaControlList.some(
      (c) => c.get('criteria')?.value && c.valid,
    );
  }

  isButtonDisabled(): boolean {
    return (
      (this.applicationVersion == 1 &&
        !this.technicalAdvisorEvaluationFiles.length) ||
      this.isSendToIAButtonDisabled()
    );
  }

  isSendToIAButtonDisabled(): boolean {
    return (
      !this.expertForm.valid ||
      (this.expertForm.get('needsFieldVisit').value &&
        !this.hasCriteriaValue()) ||
      (this.expertForm.get('needsFieldVisit').value &&
        this.fieldVisitForm.invalid) ||
      this.isSubmitButtonClicked ||
      this.isReturnToInternalAuditorBtnClicked ||
      !this.isAllCriteriaEvaluated() ||
      (this.expertForm.get('hasGeneralNotes')?.value && !this.generalNoteText)
    );
  }

  isAllCriteriaEvaluated() {
    let isAllCriteriaEvaluated: boolean = true;
    let hasTechnicalAdvisorReviewedAllTabs: boolean = true;

    const tabsArray =
      this.documentInspectorStateService.trainingProgramDocumentMapping;

    loop: for (let i = 0; i < tabsArray.length; i++) {
      const internalAuditorReview = tabsArray[
        i
      ]?.documentFileHasBeenReviewes.filter(
        (review) => review.user?.roleId === UserRole.TechnicalAdvisor,
      );

      if (
        !internalAuditorReview.length ||
        !internalAuditorReview[0].fileHasBeenReviewed
      ) {
        hasTechnicalAdvisorReviewedAllTabs = false;
        break;
      }

      const criteriaArray =
        tabsArray[i]?.programDocumentLookup?.programDocumentCriteriaMappings;

      for (let i = 0; i < criteriaArray.length; i++) {
        if (
          criteriaArray[i].isPassed === null &&
          criteriaArray[i].documentCriteriaLookup.roleId ===
            UserRole.TechnicalAdvisor
        ) {
          isAllCriteriaEvaluated = false;
          break loop;
        }
      }
    }

    return isAllCriteriaEvaluated && hasTechnicalAdvisorReviewedAllTabs;
  }

  submitHandler() {
    if (this.isButtonDisabled()) return;

    this.isSubmitButtonClicked = true;
    this.showSubmitModal = true;
    this.loadingService.startLoading();

    const needsFieldVisit = this.expertForm.value.needsFieldVisit;
    const hasGeneralNotes = this.expertForm.value.hasGeneralNotes;
    if (this.tempTechnicalAdvisorEvaluationsFileId != 0)
      this.technicalAdvisorEvaluationFiles.push({
        technicalAdvisorEvaluationsFileId:
          this.tempTechnicalAdvisorEvaluationsFileId,
        version: 0,
        file: null,
      });

    const evaluationData: any = {
      trainingOrganizationStatusEnum:
        Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary,
      trainingOrganizationId: this.id,
      HasNotes: hasGeneralNotes,
      hasFieldVisit: needsFieldVisit,
      technicalAdvisorEvaluationsFiles:
        this.technicalAdvisorEvaluationFiles.filter(
          (f) => !this.requestData.technicalAdvisorEvaluationsFiles.includes(f),
        ),
      trainingOrganizationFieldVisitForm: needsFieldVisit
        ? this.constructFieldVisitFormBody()
        : undefined,
      trainingProgramDocumentNotes: hasGeneralNotes
        ? [
            {
              id: this.technicalAdvisorLastNote?.id ?? 0,
              note: this.expertForm.value.generalNotes,
              documentNoteTypeId: DocumentNoteType.GeneralNotes,
            },
          ]
        : [
            {
              id: this.technicalAdvisorLastNote?.id ?? 0,
            },
          ],
    };
    this.submitService
      .submitEvaluation(evaluationData)
      .subscribe((response) => {
        if (response) {
          this.submittedSuccessfully = true;
          this.loadingService.stopLoading();
        }
      });
  }

  handleSaveEvaluationData() {
    this.allNotes =
      this.documentInspectorStateService.trainingProgramDocumentMapping[
        this.documentInspectorStateService.tabDataIndex
      ].programDocumentLookup?.programDocumentCriteriaMappings;
  }

  handleReturnToInternalAuditor() {
    if (!this.isAllCriteriaEvaluated()) return;

    this.loadingService.startLoading();

    const needsFieldVisit = this.expertForm.value.needsFieldVisit;
    const hasGeneralNotes = this.expertForm.value.hasGeneralNotes;

    if (this.tempTechnicalAdvisorEvaluationsFileId != 0)
      this.technicalAdvisorEvaluationFiles.push({
        technicalAdvisorEvaluationsFileId:
          this.tempTechnicalAdvisorEvaluationsFileId,
        version: this.applicationVersion,
        file: null,
      });

    const evaluationData: any = {
      trainingOrganizationStatusEnum:
        Status.ReturnedByTechnicalAdvisorToInternalAuditor,
      trainingOrganizationId: this.id,
      HasNotes: hasGeneralNotes,
      hasFieldVisit: needsFieldVisit,
      technicalAdvisorEvaluationsFiles:
        this.technicalAdvisorEvaluationFiles.filter(
          (f) => !this.requestData.technicalAdvisorEvaluationsFiles.includes(f),
        ),
      trainingOrganizationFieldVisitForm: needsFieldVisit
        ? this.constructFieldVisitFormBody()
        : undefined,
      trainingProgramDocumentNotes: hasGeneralNotes
        ? [
            {
              id: this.technicalAdvisorLastNote?.id ?? 0,
              note: this.expertForm.value.generalNotes,
              documentNoteTypeId: DocumentNoteType.GeneralNotes,
            },
          ]
        : [
            {
              id: this.technicalAdvisorLastNote?.id ?? 0,
            },
          ],
    };

    this.submitService
      .submitEvaluation(evaluationData)
      .subscribe((response) => {
        if (response) {
          this.submittedSuccessfully = true;
          this.loadingService.stopLoading();
        }
      });
  }

  constructFieldVisitFormBody() {
    const fieldVisitDataArray = [];

    for (let i = 0; i < this.fieldVisitCriteriaControlList.length; i++) {
      const element = this.fieldVisitCriteriaControlList[i];
      if (!element.get('criteria').value) continue;
      const criteriaObj = { standard: element.get('criteria').value };
      if (element.get('radio').value) {
        criteriaObj['isApplied'] = element.get('radio').value;
        criteriaObj['notes'] = element.get('note').value;
      } else {
        criteriaObj['isApplied'] = element.get('radio').value;
        criteriaObj['notes'] = element.get('note').value;
        criteriaObj['fieldVisitFormMappingFiles'] = element
          .get('files')
          .value?.map((file) => ({
            file: file,
            fileId: '',
          }));
      }
      fieldVisitDataArray.push(criteriaObj);
    }

    const generalFieldVisitFormFiles = this.fieldVisitForm
      .get('fieldVisitFiles')
      .value?.map((file) => ({ file: file, fileId: '' }));

    const fieldVisitFormNotes = this.fieldVisitForm.get('fieldVisitNotes')
      ?.value
      ? [
          {
            note: this.fieldVisitForm.get('fieldVisitNotes')?.value,
            id: '',
          },
        ]
      : null;

    return {
      trainingOrganizationId: this.id,
      fieldVisitForms: fieldVisitDataArray,
      generalFieldVisitFormFiles: generalFieldVisitFormFiles,
      fieldVisitFormNotes: fieldVisitFormNotes,
    };
  }

  showConfirmationModal() {
    this.bodyScrollService.toggleBodyScroll(false);
    this.showSubmitModal = true;
    this.isReturnToInternalAuditorBtnClicked = true;
  }

  onShowPledge(showPledge: boolean) {
    this.showPledge = showPledge;
  }

  cancelReturnToInternalAuditor() {
    this.bodyScrollService.toggleBodyScroll(true);
    this.showSubmitModal = false;
    this.isReturnToInternalAuditorBtnClicked = false;
  }

  accordionVisibilityHandler(criteriasArray: any[]) {
    return criteriasArray?.length && this.criteriaFilter(criteriasArray).length;
  }

  criteriaFilter(criteriaArr) {
    return criteriaArr?.filter(
      (item) =>
        item?.documentCriteriaLookup?.roleId ===
          this.authService.getUserRole() && item?.isPassed !== null,
    );
  }

  closeModel() {
    this.showSubmitModal = false;
    this.router.navigate(['/dashboard']);
  }

  viewFile(sectionIndex: number, currentFileIndex: number) {
    this.documentInspectorStateService.setTabDataIndex(sectionIndex);
    this.bodyScrollService.toggleBodyScroll(false);
    this.fileIndex = currentFileIndex;
    this.showModal = true;
    this.handleSaveEvaluationData();
    this.showTrainingData = false;
    this.inspectorComponent.fileIndex = currentFileIndex;
    this.inspectorComponent.fileURL = '';
    this.inspectorComponent.oldFileUrl = '';

    this.inspectorComponent.showPledge =
      sectionIndex ==
      this.documentInspectorStateService.trainingProgramDocumentMapping.length -
        1;
    this.showPledge =
      sectionIndex ==
      this.documentInspectorStateService.trainingProgramDocumentMapping.length -
        1;

    const filesArray =
      this.documentInspectorStateService.trainingProgramDocumentMapping[
        sectionIndex
      ].files[currentFileIndex].archivedFiles;
    let waitForFilesLoading = false;
    if (filesArray && filesArray.length > 1) waitForFilesLoading = true;

    this.inspectorComponent.getFileUrl(
      sectionIndex,
      currentFileIndex,
      true,
      waitForFilesLoading,
    );
    this.inspectorComponent.getOldFileUrl(
      sectionIndex,
      currentFileIndex,
      waitForFilesLoading,
    );
  }

  viewTrainingOrPledgeData(tabDataIndex: number) {
    this.documentInspectorStateService.setTabDataIndex(tabDataIndex);
    this.bodyScrollService.toggleBodyScroll(false);
    this.showTrainingData = tabDataIndex === 0;
    this.showPledge =
      tabDataIndex ===
      this.documentInspectorStateService.trainingProgramDocumentMapping.length -
        1;
    this.showModal = true;
    this.handleSaveEvaluationData();
    this.inspectorComponent.getFileUrl(0, 0, true);
  }

  getFile(file) {
    this.auditService.getFile(file.id).subscribe({
      next: (url) => {
        this.downloadFile(
          url,
          file?.fileName ??
            file?.name ??
            `expertform(${this.applicationVersion})`,
        );
      },
      error: (error) => {
        this.loadingService.stopLoading();
        console.log('error', error);
      },
    });
  }

  downloadFile(url: any, filename: string) {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename;
    anchor.click();

    window.URL.revokeObjectURL(url);
  }

  getOlderEvaluationsFiles() {
    return this.requestData.technicalAdvisorEvaluationsFiles.filter(
      (f) => f.version != this.applicationVersion,
    );
  }
}
