import { Component, Input } from '@angular/core';
import { Assets } from 'src/assets/images';
import { UserRole } from '@app/types/roles';

@Component({
  selector: 'app-prev-note',
  templateUrl: './prev-note.component.html',
  styleUrl: './prev-note.component.scss',
})
export class PrevNoteComponent {
  @Input() notes: any[];
  @Input() isDocumentInspector: boolean;
  @Input() showUserName: boolean = true;
  TrainingOrganizationRole = UserRole.TrainingOrganization;

  images = {
    checked: Assets.checkMark,
    clock: Assets.clock,
    altImage: Assets.altImage,
    calendar: Assets.calenadar,
  };
}
