<div class="container">
  <div class="banner-container">
    <div class="welcome-text__container">
      <span class="welcome">مرحبا بك في </span>
      <h1 class="welcome-text">
        اعتماد البرامج التدريبية في مجال كفاءة الطاقة
      </h1>
    </div>
    <img src="assets/images/banner.png" alt="banner" class="banner-image" />
  </div>

  <loading-spinner></loading-spinner>
  <div *ngIf="tableData?.data" class="table-container">
    <app-table
      title="إيصالات السداد"
      [headers]="headers"
      [data]="tableData?.data"
      [options]="status"
      [totalRecords]="tableData?.totalRecords"
      [showEyeButtonColumn]="false"
      [receiptsTable]="true"
      [isDateFilter]="true"
      [(isHighlighted)]="isHighlighted"
    >
    </app-table>
  </div>
</div>
