import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Assets } from '@assets/images';
import { AuthService } from 'src/app/services/auth.service';
import { UserRole } from '@app/types/roles';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent {
  banner = Assets.banner;
  showReceiptsTable: boolean;
  constructor(private authService: AuthService) {}

  isInternalAuditor(): boolean {
    return this.authService.hasRole(UserRole.InternalAuditor);
  }

  isOrganization(): boolean {
    return this.authService.hasRole(UserRole.TrainingOrganization);
  }

  isCommitteeSecretary(): boolean {
    return this.authService.hasRole(UserRole.CommitteeSecretary);
  }

  isTechnicalAdvisor(): boolean {
    return this.authService.hasRole(UserRole.TechnicalAdvisor);
  }

  isCommitteeMember(): boolean {
    return this.authService.hasRole(UserRole.CommitteeMember);
  }

  isCommitteeHead(): boolean {
    return this.authService.hasRole(UserRole.CommitteeHead);
  }

  isGeneralManager(): boolean {
    return this.authService.hasRole(UserRole.GeneralManager);
  }

  isFinancialAuditor(): boolean {
    return this.authService.hasRole(UserRole.FinancialAuditor);
  }

  isSystemAdministrator(): boolean {
    return this.authService.hasRole(UserRole.SystemAdministrator);
  }
}
