import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TrackerService {
  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getTrackerData(id: number): Observable<any> {
    return this.http.get<any>(
      this.apiUrl + `/api/TrainingProgramRequest/GetApplicationTracker/${id}`,
    );
  }
}
