import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { NotificationsService } from '@app/dashboard/components/shared/notifications-drop-down/notifications.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private notificationService: NotificationsService,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    const selectedUserRole = this.authService.getSelectedUserRole();

    if (token) {
      let headers = request.headers
        .append('X-Content-Type-Options', 'nosniff')
        .append('X-Frame-Options', 'DENY')
        .append('strict-transport-security', 'max-age=31536000')
        .append('X-XSS-Protection', '1; mode=block')
        .append('Authorization', `Bearer ${token}`);

      if (selectedUserRole !== null && !isNaN(selectedUserRole)) {
        headers = headers.append('selectedUserRole', `${selectedUserRole}`);
      }

      request = request.clone({ headers });

      this.notificationService.createHubConnection(token);
    }
    return next.handle(request);
  }
}
