import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TrackerService } from '@app/dashboard/services/tracking.service';

@Component({
  selector: 'app-status-tracker',
  templateUrl: './status-tracker.component.html',
  styleUrls: ['./status-tracker.component.scss'],
})
export class StatusTrackerComponent implements OnInit {
  steps: any[] = [];

  constructor(
    private trackerService: TrackerService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const id = params['id'];
      if (id) {
        this.trackerService.getTrackerData(id).subscribe((response) => {
          if (response.errorCode === 0) {
            this.steps = this.transformTrackerData(response.data);
          }
        });
      }
    });
  }

  private transformTrackerData(data: any[]): any[] {
    const steps = [];
    let currentStep = null;

    data.forEach((item) => {
      if (item.sizeIcon == 2) {
        currentStep = {
          stage: item.stageName,
          date: this.formatDate(item.createdAt),
          actions: [],
          icon: `assets/icons/track-icons/${item.stageIcon}`,
        };
        steps.push(currentStep);
      } else if (item.sizeIcon == 1) {
        if (!currentStep) {
          currentStep = {
            stage: '',
            date: null,
            actions: [],
            icon: null,
          };
          steps.push(currentStep);
        }
        currentStep.actions.push({
          description: item.stageName,
          icon: `assets/icons/track-icons/${item.stageIcon}`,
          date: this.formatDate(item.createdAt),
        });
      }
    });

    return steps;
  }

  private formatDate(dateString: string): string {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
    };

    const formattedDate = new Date(dateString).toLocaleDateString(
      'ar-EG',
      options,
    );

    const parts = formattedDate.split('، ');
    if (parts.length === 2) {
      return `${parts[1]}، ${parts[0]}`;
    }

    return formattedDate;
  }
}
