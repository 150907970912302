import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedRoutingModule } from './shared-routing.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MenuModule } from 'primeng/menu';
import { EditorModule } from 'primeng/editor';
import { SkeletonModule } from 'primeng/skeleton';

import { DataService } from '../dashboard/services/data.service';

import { FooterComponent } from './footer/footer.component';
import { SigninFormComponent } from './signin-form/signin-form.component';
import { SignupFormComponent } from './signup-form/signup-form.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SvgDefinitionsComponent } from './svg-definitions/svg-definitions.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';
import { HeaderComponent } from './header/header.component';
import { VerifyOTPComponent } from './verify-otp/verify-otp.component';
import { CustomButtonComponent } from './custom-button/custom-button.component';
import { CreateNewPasswordComponent } from './create-new-password/create-new-password.component';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { UserGuideComponent } from './user-guide/user-guide.component';
import { FaqComponent } from './faq/faq.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { UsagePolicyComponent } from './usage-policy/usage-policy.component';
import { ProfileComponent } from './profile/profile.component';
import { ViewEvaluationComponent } from './view-evaluation/view-evaluation.component';
import { PrevNoteComponent } from './prev-note/prev-note.component';
import { ExtendedPdfViewerComponent } from '../extended-pdf-viewer/extended-pdf-viewer.component';
import { NotificationsDropDownComponent } from '@app/dashboard/components/shared/notifications-drop-down/notifications-drop-down.component';
import { ClickOutsideDirective } from '@app/dashboard/components/shared/document-inspector/clickOutside.directive';
import { NotificationsService } from '@app/dashboard/components/shared/notifications-drop-down/notifications.service';
import { HeaderService } from '@app/services/header.service';
import { DialogModule } from 'primeng/dialog';
import { FormsModule } from '@angular/forms';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    FooterComponent,
    SigninFormComponent,
    SignupFormComponent,
    ForgotPasswordComponent,
    SvgDefinitionsComponent,
    SvgIconComponent,
    HeaderComponent,
    VerifyOTPComponent,
    CustomButtonComponent,
    CreateNewPasswordComponent,
    SubHeaderComponent,
    UserGuideComponent,
    FaqComponent,
    PrivacyPolicyComponent,
    UsagePolicyComponent,
    ProfileComponent,
    ExtendedPdfViewerComponent,
    ViewEvaluationComponent,
    PrevNoteComponent,
    NotificationsDropDownComponent,
    ClickOutsideDirective,
  ],
  imports: [
    SkeletonModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedRoutingModule,
    MenuModule,
    EditorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    PdfViewerModule,
    NgxExtendedPdfViewerModule,
    DialogModule,
    FormsModule,
  ],
  exports: [
    SkeletonModule,
    FooterComponent,
    SigninFormComponent,
    SignupFormComponent,
    ForgotPasswordComponent,
    TranslateModule,
    ReactiveFormsModule,
    SvgDefinitionsComponent,
    SvgIconComponent,
    HeaderComponent,
    CustomButtonComponent,
    SubHeaderComponent,
    UserGuideComponent,
    PdfViewerModule,
    NgxExtendedPdfViewerModule,
    FaqComponent,
    PrivacyPolicyComponent,
    UsagePolicyComponent,
    ProfileComponent,
    MenuModule,
    ViewEvaluationComponent,
    PrevNoteComponent,
    EditorModule,
    ExtendedPdfViewerComponent,
    NotificationsDropDownComponent,
    ClickOutsideDirective,
    DialogModule,
    FormsModule,
  ],
  providers: [DataService, NotificationsService, HeaderService],
})
export class SharedModule {}
