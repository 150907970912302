<div class="banner-container">
  <div class="welcome-text__container">
    <span class="welcome">مرحبا بك في </span>
    <h1 class="welcome-text">لوحة التحكم الرئيسية</h1>
  </div>
  <img [src]="banner" alt="banner" class="banner-image" />
</div>
<div class="container">
  <div>
    <p-tabView [activeIndex]="activeTabIndex" (onChange)="onTabChange($event)">
      <loading-spinner />

      <p-tabPanel
        header="عدد الطلبات للبرامج التي قامت الجهات التدريبية بتأليفها"
      >
        <div class="subject">
          <span>تقرير االتحليل الرباعي</span>
          <app-chart
            [chartType]="chartType"
            [chartData]="annualReport"
            [currentYear]="annualReportYear"
            [chartTitle]="annualReportLabel"
            (previousYear)="previousYear(reportCategory.AnnualReport)"
            (nextYear)="nextYear(reportCategory.AnnualReport)"
          >
          </app-chart>
          <div>
            <app-chart
              [chartType]="chartType"
              [chartData]="semiAnnualReport"
              [currentYear]="semiAnnualReportYear"
              [chartTitle]="semiAnnualReportLabel"
              (previousYear)="previousYear(reportCategory.SemiAnnualReport)"
              (nextYear)="nextYear(reportCategory.SemiAnnualReport)"
            >
            </app-chart>
          </div>
          <div>
            <app-chart
              [chartType]="chartType"
              [chartData]="quarterReport"
              [currentYear]="quarterReportYear"
              [chartTitle]="quarterReportLabel"
              (previousYear)="previousYear(reportCategory.QuarterReport)"
              (nextYear)="nextYear(reportCategory.QuarterReport)"
            >
            </app-chart>
          </div>
        </div>
      </p-tabPanel>
      <p-tabPanel header="عدد الطلبات للبرامج التي عمل المركز على إعدادها">
        <div class="subject">
          <span>تقرير التحليل الرباعي
          </span>
          <app-chart
            [chartType]="chartType"
            [chartData]="annualReport"
            [currentYear]="annualReportYear"
            [chartTitle]="annualReportLabel"
            (previousYear)="previousYear(reportCategory.AnnualReport)"
            (nextYear)="nextYear(reportCategory.AnnualReport)"
          >
          </app-chart>
          <div>
            <app-chart
              [chartType]="chartType"
              [chartData]="semiAnnualReport"
              [currentYear]="semiAnnualReportYear"
              [chartTitle]="semiAnnualReportLabel"
              (previousYear)="previousYear(reportCategory.SemiAnnualReport)"
              (nextYear)="nextYear(reportCategory.SemiAnnualReport)"
            >
            </app-chart>
          </div>
          <div>
            <app-chart
              [chartType]="chartType"
              [chartData]="quarterReport"
              [currentYear]="quarterReportYear"
              [chartTitle]="quarterReportLabel"
              (previousYear)="previousYear(reportCategory.QuarterReport)"
              (nextYear)="nextYear(reportCategory.QuarterReport)"
            >
            </app-chart>
          </div></div
      ></p-tabPanel>
    </p-tabView>
  </div>
</div>
