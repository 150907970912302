<div class="data-table">
    <div class="btn-container">
        <h2>{{ title }}</h2>
        <button pButton type="button" label="تصدير" (click)="exportPdf()">
          <i class="pi pi-download"></i>
        </button>
      </div>

    <p-table [value]="data" >
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let header of headers">
            {{ header.header }} 
          </th>
        </tr>
      </ng-template>
  
      <ng-template pTemplate="body" let-rowData class="table-data">
        <tr>
          <td *ngFor="let header of headers">{{ rowData[header.field] }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  