import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { MatInputModule } from '@angular/material/input';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './components/shared.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { PublicPageModule } from './public_page/public-page.module';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { ErrorInterceptor } from './services/error-interceptor.service';
import { ResponseInterceptorService } from './services/response-interceptor.service';
import { DatePipe } from '@angular/common';

import { AppComponent } from './app.component';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    DashboardModule,
    AppRoutingModule,
    PublicPageModule,
    SharedModule,
    MatInputModule,
  ],
  exports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptorService,
      multi: true,
    },

    HttpClientModule,
    DatePipe,
    provideAnimationsAsync(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
