import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import {
  AccreditationFormService,
  NewAccreditationForm,
  UpdateAccreditationForm,
} from 'src/app/dashboard/services/accreditation-form.service';
import { AuditService } from 'src/app/dashboard/services/audit.service';
import { BodyScrollService } from 'src/app/dashboard/services/body-scroll.service';
import { ModalService } from 'src/app/dashboard/services/modal.service';
import { LookupsTypeEnum } from 'src/app/dashboard/types/lookUps';
import { Status } from 'src/app/dashboard/types/status';
import { Tab } from 'src/app/dashboard/types/tabs.type';
import { GetLookupsService } from 'src/app/get-look-ups.service';
import { Formatter } from 'src/utils/formatters';
import { DropdownOption } from '../dropdown/dropdown.component';
import { LoadingService } from 'src/app/services/loading.service';
import { Observable } from 'rxjs';
import { Rejex } from 'src/utils/rejex';
import { AuthService } from 'src/app/services/auth.service';
import { UserRole } from '@app/types/roles';
import { DocumentInspectorStateService } from 'src/app/services/document-inspector-state.service';
import { DocumentInspectorComponent } from '../document-inspector/document-inspector.component';
import { DocumentNoteType } from '@app/dashboard/types/documentNote.type';
import { environment } from '@environments/environment';
import { PaymentDetailsComponent } from '../../payment-details/payment-details.component';

@Component({
  selector: 'app-form-tabs',
  templateUrl: './form-tabs.component.html',
  styleUrls: ['./form-tabs.component.scss'],
})
export class FormTabsComponent implements OnInit {
  @ViewChild('signatureInput') signatureInput: ElementRef;
  @Input() tabs: Tab[] = [];
  @Input() activeTabIndex: number = 0;
  @Input() showRejectionReason: boolean;
  isReadOnly: boolean = false;
  @Output() activeTabIndexChange = new EventEmitter<number>();
  @Output() reportIdEmitter = new EventEmitter<number | string>();
  @Output() renewableApplicationEmitter = new EventEmitter<boolean>();
  @Output() showRejectionEmitter = new EventEmitter<boolean>();

  id: number;
  requestData: any = {};
  signatureImageUrl: string = '/assets/images/signature.png';
  selectedFiles: File[] = [];
  showModal = false;
  savingAsDraft = false;
  cities: DropdownOption[] = [];
  accreditationApplicationTypes: DropdownOption[] = [];
  trainingProgramLevels: DropdownOption[] = [];
  trainingProgramType: DropdownOption[] = [];
  trainingProgramLanguages: DropdownOption[] = [];
  requiredDocuments: DropdownOption[] = [];
  submittedSuccessfully: boolean = false;
  certificateData: any = null;
  licenseDateError: boolean = false;
  licenseExpiryDateError: boolean = false;
  isAcceptedImgFormat: boolean = true;
  licenseExpiryMinDate: Date = new Date(
    new Date().setDate(new Date().getDate() + 1),
  );
  licenseMaxDate: Date = new Date();
  isEditableApplication: boolean = false;
  isDraft: boolean = false;
  numberOnlyKeyFilter: RegExp = Rejex.NumbersOnly;
  accreditationForm: FormGroup;
  edittingTrainingOrgTab: boolean = false;
  edittingTrainingProgramTab: boolean = false;
  edittingTrainingDocumentsTab: boolean = false;
  edittingGeneralCommitmentTab: boolean = false;
  fileIndex: number | string = 0;
  isInspectorModalOpen: boolean = false;
  requestStatus: number;
  allNotes: any[] = [];
  updateRequestNumber: number = 1;
  showTrainingData: boolean = false;
  showPledge: boolean = false;
  programDocumentsValidationErrors: { [id: string]: boolean } = {};
  isProgramDocumentsSaveButtonDisabled: boolean = false;
  fieldVisitData: any = null;
  isDialogOpen: boolean = false;
  isCertificateDownloadable: boolean;
  isRenewalApplication: boolean;
  isRejectedApplication: boolean;
  showPaymentData: boolean;
  showSendInvoiceButton: boolean;
  isSendInvoiceDisabled: boolean = true;

  @ViewChild('paymentComponent')
  paymentComponent: PaymentDetailsComponent;
  @ViewChild('inspectorComponent')
  inspectorComponent: DocumentInspectorComponent;

  constructor(
    private route: ActivatedRoute,
    private auditService: AuditService,
    private fb: FormBuilder,
    private el: ElementRef,
    private bodyScrollService: BodyScrollService,
    public modalService: ModalService,
    private router: Router,
    private dropdownsOptionsService: GetLookupsService,
    private submitService: AccreditationFormService,
    private loadingService: LoadingService,
    private authService: AuthService,
    public documentInspectorStateService: DocumentInspectorStateService,
  ) {
    this.viewFile = this.viewFile.bind(this);
    bodyScrollService.modalScrollEnabled.subscribe((enableScroll) => {
      if (enableScroll) {
        document.body.classList.remove('modal-open');
      } else {
        document.body.classList.add('modal-open');
      }
    });
  }

  isLoading: Observable<boolean> = this.loadingService.isLoading$();

  getCurrentRoute(): string {
    return this.router.url;
  }

  onSubmit({ asDraft = false }): void {
    this.bodyScrollService.toggleBodyScroll(false);
    this.savingAsDraft = asDraft;
    this.toggleRenewalFields(true);

    const fieldVisitFormStandards = [];
    const trainingOrganizationFieldVisitForm: { Id?: number | string } = {};
    if (this.accreditationForm.get('fieldVisitRequiredChangesDone')?.value) {
      const fieldVisit = this.fieldVisitData.at(-1);
      trainingOrganizationFieldVisitForm.Id = fieldVisit?.id;

      fieldVisit.fieldVisitForms.forEach((std, index) => {
        if (!std.isApplied) {
          const files = this.accreditationForm
            .get('fieldVisitStandardsFiles')
            ?.value[index]?.map((file) => ({ file: file, fileId: '' }));

          fieldVisitFormStandards.push({
            Id: std.id,
            fieldVisitFormMappingFiles: files,
          });
        }
      });
    }

    const requestBody: NewAccreditationForm | UpdateAccreditationForm = {
      trainingOrganization: {
        id: this.id ? this.requestData?.trainingOrganization?.id : undefined,
        organizationName: this.accreditationForm.value.agencyName,
        coordinatorName: this.accreditationForm.value.cordinatorName,
        trainingLicenseNumber: this.accreditationForm.value.licenseNumber,
        licenseDate: this.accreditationForm.value.licenseDate,
        licenseExpirationDate: this.accreditationForm.value.licenseExpiryDate,
        address: this.accreditationForm.value.address,
        phoneNumber: this.accreditationForm.value.mobileNumber,
        email: this.accreditationForm.value.email,
        isSubmitted: !asDraft,
        requestTypeId: this.accreditationForm.value.applicationType,
        cityId: this.accreditationForm.value.city,
      },
      trainingProgram: {
        id: this.id ? this.requestData?.trainingProgram?.id : undefined,
        nameAr: this.accreditationForm.value.trainingProgramName,
        nameEn: this.accreditationForm.value.trainingProgramEnglishName,
        programLevelLookupId: this.accreditationForm.value.trainingProgramLevel,
        trainingProgramTypeId: this.accreditationForm.value.trainingProgramType,
        trainingProgramDuration:
          this.accreditationForm.value.trainingProgramDays,
        totalTrainingHours: this.accreditationForm.value.trainingProgramHours,
        programObjective: this.accreditationForm.value.trainingProgramPurpose,
        languageLookupId: this.accreditationForm.value.trainingProgramLanguage,
        targetCategory: this.accreditationForm.value.targetedCategory,
        description: this.accreditationForm.value.programDescription,
        isSubmitted: !asDraft,
      },
      trainingProgramDocuments: {
        id: this.id
          ? this.requestData?.trainingProgramDocuments?.id
          : undefined,
        isSubmitted: !asDraft,
        programDocuments: this.getProgramDocuments(),
      },
      generalCommitment: {
        id: this.id ? this.requestData?.generalCommitment?.id : undefined,
        organizationName: this.accreditationForm.value.agencyName,
        applicantsName: this.accreditationForm.value.applicantName,
        file:
          this.accreditationForm.value.signature instanceof File
            ? this.accreditationForm.value.signature
            : '',
        fileId:
          this.accreditationForm.value.signature instanceof File
            ? ''
            : this.accreditationForm.value.signature
              ? this.requestData?.generalCommitment?.generalCommitmentFile?.file
                  ?.fileId
              : '',
        generalCommitmentFileId:
          this.requestData?.generalCommitment?.generalCommitmentFile?.file
            ?.generalCommitmentFileId || 0,
        isSubmitted: !asDraft,
      },
      trainingOrganizationFieldVisitForm: trainingOrganizationFieldVisitForm,
      fieldVisitForms: fieldVisitFormStandards || null,
    };
    this.toggleRenewalFields(false);
    this.isDialogOpen = false;
    this.showModal = true;
    this.submitService
      .sendNewApplicationForm(requestBody)
      .subscribe((response) => {
        if (response.data) this.submittedSuccessfully = true;
      });
  }

  onUpdateTrainingOrganizationSection(): void {
    this.toggleRenewalFields(true);

    const requestBody: UpdateAccreditationForm['trainingOrganization'] = {
      id: this.requestData?.trainingOrganization?.id,
      organizationName: this.accreditationForm.value.agencyName,
      coordinatorName: this.accreditationForm.value.cordinatorName,
      trainingLicenseNumber: this.accreditationForm.value.licenseNumber,
      licenseDate: this.accreditationForm.value.licenseDate,
      licenseExpirationDate: this.accreditationForm.value.licenseExpiryDate,
      address: this.accreditationForm.value.address,
      phoneNumber: this.accreditationForm.value.mobileNumber,
      email: this.accreditationForm.value.email,
      isSubmitted: true,
      requestTypeId: this.accreditationForm.value.applicationType,
      cityId: this.accreditationForm.value.city,
    };
    this.toggleRenewalFields(false);
    this.submitService
      .updateTrainingOrganizationSection(requestBody)
      .subscribe((response) => {
        if (response.data) {
          this.requestData['trainingOrganization'] = {
            ...this.requestData['trainingOrganization'],
            ...{
              organizationName: response.data.organizationName,
              coordinatorName: response.data.coordinatorName,
              trainingLicenseNumber: response.data.trainingLicenseNumber,
              licenseDate: response.data.licenseDate,
              licenseExpirationDate: response.data.licenseExpirationDate,
              address: response.data.address,
              phoneNumber: response.data.phoneNumber,
              email: response.data.email,
              isSubmitted: true,
              requestTypeName: this.getValueByKey(
                this.accreditationApplicationTypes,
                response.data.requestTypeId,
              ),
              cityName: this.getValueByKey(this.cities, response.data.cityId),
            },
          };
          this.edittingTrainingOrgTab = false;
        }
      });
  }

  onUpdateTrainingProgramSection(): void {
    this.toggleRenewalFields(true);

    const requestBody: UpdateAccreditationForm['trainingProgram'] = {
      trainingOrganizationId: this.requestData?.trainingOrganization?.id,
      id: this.requestData?.trainingProgram?.id,
      nameAr: this.accreditationForm.value.trainingProgramName,
      nameEn: this.accreditationForm.value.trainingProgramEnglishName,
      programLevelLookupId: this.accreditationForm.value.trainingProgramLevel,
      trainingProgramTypeId: this.accreditationForm.value.trainingProgramType,
      trainingProgramDuration: this.accreditationForm.value.trainingProgramDays,
      totalTrainingHours: this.accreditationForm.value.trainingProgramHours,
      programObjective: this.accreditationForm.value.trainingProgramPurpose,
      languageLookupId: this.accreditationForm.value.trainingProgramLanguage,
      targetCategory: this.accreditationForm.value.targetedCategory,
      description: this.accreditationForm.value.programDescription,
      isSubmitted: true,
    };
    this.toggleRenewalFields(false);
    this.submitService
      .updateTrainingProgramSection(requestBody)
      .subscribe((response) => {
        if (response.data) {
          this.requestData['trainingProgram'] = {
            ...this.requestData['trainingProgram'],
            ...requestBody,
            ...response.data,
            programLevelName: this.getValueByKey(
              this.trainingProgramLevels,
              response.data.programLevelLookupId,
            ),
            trainingProgramType: this.getValueByKey(
              this.trainingProgramType,
              response.data.trainingProgramTypeId,
            ),
            languageLookupName: this.getValueByKey(
              this.trainingProgramLanguages,
              response.data.languageLookupId,
            ),
          };
          this.edittingTrainingProgramTab = false;
        }
      });
  }

  onUpdateDocumentFilesSection(): void {
    this.isProgramDocumentsSaveButtonDisabled = true;
    const requestBody: UpdateAccreditationForm['trainingProgramDocuments'] = {
      trainingOrganizationId: this.requestData?.trainingOrganization?.id,
      id: this.requestData?.trainingProgramDocuments?.id,
      isSubmitted: true,
      programDocuments: this.getProgramDocuments(),
    };
    this.submitService
      .updateDocumentFilesSection(requestBody)
      .subscribe((response) => {
        if (response.data) {
          this.requestData['trainingProgramDocuments'] = {
            ...this.requestData['trainingProgramDocuments'],
            ...response.data,
          };
          this.documentInspectorStateService.setTrainingProgramDocumentMapping(
            response.data.trainingProgramDocumentMapping,
          );
          this.resetTrainingDocumentsTab();
        }
      });
  }

  onUpdateGeneralCommitmentSection(): void {
    const requestBody: UpdateAccreditationForm['generalCommitment'] = {
      trainingOrganizationId: this.requestData?.trainingOrganization?.id,
      id: this.requestData?.generalCommitment?.id,
      organizationName: this.accreditationForm.getRawValue()?.agencyName,
      applicantsName: this.accreditationForm.value.applicantName,
      file:
        this.accreditationForm.value.signature instanceof File
          ? this.accreditationForm.value.signature
          : '',
      fileId:
        this.accreditationForm.value.signature instanceof File
          ? ''
          : this.accreditationForm.value.signature
            ? this.requestData?.generalCommitment?.generalCommitmentFile?.file
                ?.fileId
            : '',
      generalCommitmentFileId:
        this.requestData?.generalCommitment?.generalCommitmentFile?.file
          ?.generalCommitmentFileId || 0,
      isSubmitted: true,
    };
    this.submitService
      .updateGeneralCommitmentSection(requestBody)
      .subscribe((response) => {
        if (response.data) {
          this.requestData['generalCommitment'] = {
            ...this.requestData['generalCommitment'],
            ...requestBody,
            ...response.data,
          };
          this.edittingGeneralCommitmentTab = false;
        }
      });
  }

  readOnly() {
    this.isReadOnly = false;
  }

  ngOnInit() {
    this.initializeComponentBasedOnRoute();
  }

  filterCriteria(criteriaArr: any, technicalAdvisorCriteria?: boolean) {
    if (technicalAdvisorCriteria)
      return criteriaArr?.filter(
        (item) =>
          !item?.isPassed &&
          item?.isPassed !== null &&
          item?.documentCriteriaLookup.roleId === UserRole.TechnicalAdvisor,
      );
    else
      return criteriaArr?.filter(
        (item) =>
          !item?.isPassed &&
          item?.isPassed !== null &&
          item?.documentCriteriaLookup.roleId === UserRole.InternalAuditor,
      );
  }

  openModal() {
    this.showModal = true;
    this.bodyScrollService.toggleBodyScroll(false);
  }

  getFileSize(sizeInBytes: number): string {
    const kbSize = sizeInBytes / 1024;

    return kbSize < 1024
      ? kbSize.toFixed(2) + ' KB'
      : (kbSize / 1024).toFixed(2) + ' MB';
  }

  openFileInput() {
    if (this.signatureInput) {
      this.signatureInput.nativeElement.click();
    }
  }

  onImageSelected(event: any) {
    const selectedFile = event.target.files[0];
    const allowedImgExtensions = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'image/gif',
      'image/bmp',
      'image/tiff',
      'image/webp',
      'image/svg+xml',
    ];

    if (selectedFile) {
      if (!allowedImgExtensions.includes(selectedFile?.type)) {
        this.isAcceptedImgFormat = false;
        return;
      }

      this.isAcceptedImgFormat = true;
      this.accreditationForm.patchValue({ signature: selectedFile });
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.signatureImageUrl = e.target.result;
      };
      reader.readAsDataURL(selectedFile);
    }
  }

  deleteSignature(event: Event) {
    event.stopPropagation();
    this.accreditationForm.get('signature').patchValue(null);
    this.signatureInput.nativeElement.value = '';
    this.signatureImageUrl = '/assets/images/signature.png';
  }

  navigateToPreviousTab = (): void => {
    if (this.activeTabIndex > 0) {
      this.activeTabIndex--;
      this.scrollIntoView();
    }
  };

  navigateToNextTab = (): void => {
    if (this.activeTabIndex < this.tabs.length - 1) {
      this.activeTabIndex++;
      this.scrollIntoView();
    }
  };

  scrollIntoView() {
    this.el.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  userGuideManual() {
    this.modalService.openModal();
  }

  closeModel() {
    this.showModal = false;
    this.router.navigate(['/dashboard']);
  }

  isTrainingOrganization() {
    return this.authService.hasRole(UserRole.TrainingOrganization);
  }

  getProgramDocuments() {
    const items = [];
    this.requiredDocuments.forEach((requiredDocument) => {
      const programDocumentLookupId = requiredDocument['programDocuments'].key;
      const fieldName = `fileField_${programDocumentLookupId}`;
      const files = this.accreditationForm.value[fieldName];

      const processedFiles = files
        .map((item) => {
          const archivedFiles = item.archivedFiles || [];
          return archivedFiles.length > 0
            ? archivedFiles.map((archivedFile) => ({
                trainingProgramDocumentMappingFileId:
                  item.trainingProgramDocumentMappingFileId || 0,
                file: archivedFile.file || item.file,
                fileId: archivedFile.file?.fileId || '',
                archivedFileId: archivedFile.archivedFileId || 0,
              }))
            : [
                // Add new or delete:
                {
                  trainingProgramDocumentMappingFileId:
                    item.trainingProgramDocumentMappingFileId || 0,
                  file: item || '',
                  fileId: '',
                  archivedFileId: item?.archivedFileId || 0,
                },
              ];
        })
        .flat();

      items.push({
        programDocumentLookupId,
        files: processedFiles,
      });
    });

    return items;
  }

  subscribeToFormChanges() {
    const validationFunction = () => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      // extract controls values
      const licenseDateValue = this.accreditationForm.get('licenseDate')?.value;
      const licenseExpiryDateValue =
        this.accreditationForm.get('licenseExpiryDate')?.value;
      // regex to test string format 00[day]/00[month]/0000[year]
      const stringFormatRegex = /^\d{2}\/\d{2}\/\d{4}$/;

      const licenseDate = stringFormatRegex.test(licenseDateValue)
        ? Formatter.dateFromString(licenseDateValue)
        : licenseDateValue;
      const licenseExpiryDate = stringFormatRegex.test(licenseExpiryDateValue)
        ? Formatter.dateFromString(licenseExpiryDateValue)
        : licenseExpiryDateValue;

      if (
        !this.id &&
        licenseDateValue &&
        today.getTime() < licenseDate.getTime()
      ) {
        this.licenseDateError = true;
      } else {
        this.licenseDateError = false;
      }

      if (
        licenseDateValue &&
        licenseExpiryDateValue &&
        licenseDate >= licenseExpiryDate
      ) {
        this.licenseExpiryDateError = true;
      } else {
        this.licenseExpiryDateError = false;
      }
    };

    // subscribe to form changes for specified controls and run the validation on first load
    validationFunction();

    const controlsToSubscribe = ['licenseDate', 'licenseExpiryDate'];
    controlsToSubscribe.forEach((control) => {
      this.accreditationForm
        .get(control)
        ?.valueChanges.subscribe(validationFunction);
    });
  }

  filesValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const files = control.value;

      if (!Array.isArray(files) || files.length === 0) {
        return { required: true };
      }

      const invalidFiles = files.filter(({ file }) => {
        return file === '';
      });

      if (invalidFiles?.length && invalidFiles.length == files.length) {
        return { requiredFiles: true };
      }

      return null;
    };
  }

  initializeForm() {
    this.accreditationForm = this.fb.group({
      agencyName: [
        this.requestData?.trainingOrganization?.organizationName || '',
        [
          Validators.pattern(Rejex.ArabicAndEnglishCharacters),
          Validators.required,
        ],
      ],
      applicationType: [
        this.requestData?.trainingOrganization?.requestTypeId || '',
        Validators.required,
      ],
      licenseNumber: [
        this.requestData?.trainingOrganization?.trainingLicenseNumber || '',
        Validators.required,
      ],
      licenseDate: [
        this.requestData?.trainingOrganization?.licenseDate || '',
        Validators.required,
      ],
      licenseExpiryDate: [
        this.requestData?.trainingOrganization?.licenseExpirationDate || '',
        Validators.required,
      ],
      city: [
        this.requestData?.trainingOrganization?.cityId || '',
        Validators.required,
      ],
      address: [
        this.requestData?.trainingOrganization?.address || '',
        [
          Validators.pattern(Rejex.ArabicAndEnglishCharacters),
          Validators.required,
        ],
      ],
      email: [
        this.requestData?.trainingOrganization?.email || '',
        [Validators.pattern(Rejex.emailOnly), Validators.required],
      ],
      mobileNumber: [
        this.requestData?.trainingOrganization?.phoneNumber || '',
        Validators.required,
      ],
      cordinatorName: [
        this.requestData?.trainingOrganization?.coordinatorName || '',
        Validators.required,
      ],
      trainingProgramName: [
        this.requestData?.trainingProgram?.nameAr || '',
        [
          Validators.pattern(Rejex.ArabicAndEnglishCharacters),
          Validators.required,
        ],
      ],
      trainingProgramEnglishName: [
        this.requestData?.trainingProgram?.nameEn || '',
        [
          Validators.pattern(Rejex.ArabicAndEnglishCharacters),
          Validators.required,
        ],
      ],
      trainingProgramType: [
        this.requestData?.trainingProgram?.trainingProgramTypeId || '',
        Validators.required,
      ],
      trainingProgramLevel: [
        this.requestData?.trainingProgram?.programLevelLookupId || '',
        Validators.required,
      ],
      trainingProgramDays: [
        this.requestData?.trainingProgram?.trainingProgramDuration || '',
        Validators.required,
      ],
      trainingProgramHours: [
        this.requestData?.trainingProgram?.totalTrainingHours || '',
        Validators.required,
      ],
      trainingProgramPurpose: [
        this.requestData?.trainingProgram?.programObjective || '',
        Validators.required,
      ],
      trainingProgramLanguage: [
        this.requestData?.trainingProgram?.languageLookupId || null,
        Validators.required,
      ],
      targetedCategory: [
        this.requestData?.trainingProgram?.targetCategory || '',
        Validators.required,
      ],
      programDescription: [
        this.requestData?.trainingProgram?.description || '',
        Validators.required,
      ],
      applicantName: [
        this.requestData?.generalCommitment?.applicantsName || '',
        Validators.required,
      ],
      signature: [
        this.requestData?.generalCommitment?.generalCommitmentFile?.file?.id ||
          '',
      ],
      pledge: [
        this.requestData?.trainingOrganization?.isSubmitted || false,
        Validators.requiredTrue,
      ],
    });
    this.toggleRenewalFields(false);
  }

  toggleRenewalFields(value: boolean) {
    if (this.isRenewalApplication) {
      if (value) {
        this.accreditationForm.get('agencyName')?.enable();
        this.accreditationForm.get('applicationType')?.enable();
        this.accreditationForm.get('trainingProgramName')?.enable();
        this.accreditationForm.get('trainingProgramEnglishName')?.enable();
      } else {
        this.accreditationForm.get('agencyName')?.disable();
        this.accreditationForm.get('applicationType')?.disable();
        this.accreditationForm.get('trainingProgramName')?.disable();
        this.accreditationForm.get('trainingProgramEnglishName')?.disable();
      }
    }
  }

  fetchAndAddDynamicFileFields(): void {
    this.dropdownsOptionsService.getFileUploadData().subscribe({
      next: (res) => {
        this.requiredDocuments = res.data;
        this.addDynamicFileFields();
      },
    });
  }

  addDynamicFileFields() {
    this.requiredDocuments.forEach((document) => {
      const fieldName = `fileField_${document['programDocuments']?.key}`;
      const documentData =
        this.requestData?.trainingProgramDocuments?.trainingProgramDocumentMapping?.find(
          (mapping) =>
            mapping.programDocumentLookup.id ===
            document['programDocuments'].key,
        );

      this.accreditationForm.addControl(
        fieldName,
        this.fb.control(documentData?.files || [], [
          Validators.required,
          Validators.minLength(1),
          this.filesValidator(),
        ]),
      );
    });
  }

  initializeComponentBasedOnRoute() {
    this.route.params.subscribe((params) => {
      if (params['id']) {
        this.loadingService.startLoading();
        this.id = params['id'];
        this.auditService.getApplicationData(this.id).subscribe((response) => {
          if (response.data) {
            this.requestData = response.data;
            this.documentInspectorStateService.setTrainingProgramDocumentMapping(
              response.data?.trainingProgramDocuments
                ?.trainingProgramDocumentMapping,
            );

            this.requestStatus =
              response.data.workFlowTrainingOrganization.trainingOrganizationStatusLookupId;

            if (this.requestData.trainingOrganization.applicationParentId)
              this.isRenewalApplication = true;

            this.allNotes =
              response.data?.trainingProgramDocuments?.trainingProgramDocumentMapping?.[
                this.documentInspectorStateService?.tabDataIndex
              ]?.programDocumentLookup?.programDocumentCriteriaMappings;

            this.updateRequestNumber =
              response.data?.trainingProgramDocuments?.updateRequestNumber;

            if (
              this.requestData.generalCommitment.generalCommitmentFile.file
                .fileId
            ) {
              this.auditService
                .getFile(
                  this.requestData.generalCommitment.generalCommitmentFile.file
                    .id,
                )
                .subscribe({
                  next: (data) => {
                    this.signatureImageUrl = data;
                  },
                  error: (error) => {
                    console.log('error', error);
                  },
                });
            }
            this.isEditableApplication =
              this.requestStatus ===
              Status.ReturnedByInternalAuditorToTrainingOrganization;
            this.isDraft = this.requestStatus === Status.Draft;

            if (this.requestData.trainingOrganizationFieldVisitForms?.length) {
              this.fieldVisitData =
                this.requestData.trainingOrganizationFieldVisitForms;
              this.tabs.push({
                label: 'field-visit',
                content: 'تقييم الزيارة الميدانية',
              });
            }

            if (
              this.requestStatus ===
                Status.ApprovedByCommitteeSecretaryWaitingPayment ||
              this.requestStatus ===
                Status.ApprovedByTheGeneralManagerAndTheCertificateHasBeenIssued ||
              this.requestStatus ===
                Status.CertificateHasBeenRenewedByGeneralManagerToTrainingOrganization ||
              this.requestStatus >=
                Status.RejectedByFinancialAuditorToTrainingOrganization
            ) {
              if (
                !(
                  this.requestStatus >
                  Status.RejectedByFinancialAuditorToTrainingOrganization
                ) &&
                this.requestStatus !==
                  Status.ApprovedByTheGeneralManagerAndTheCertificateHasBeenIssued &&
                this.requestStatus !==
                  Status.CertificateHasBeenRenewedByGeneralManagerToTrainingOrganization
              )
                this.showSendInvoiceButton = true;

              this.showPaymentData = true;
              this.tabs.push({
                label: 'payment-receipt',
                content: 'تفاصيل الدفع',
              });
            }

            if (
              this.requestStatus ===
                Status.ApprovedByTheGeneralManagerAndTheCertificateHasBeenIssued ||
              this.requestStatus ===
                Status.CertificateHasBeenRenewedByGeneralManagerToTrainingOrganization
            ) {
              if (
                this.requestData.workFlowTrainingOrganization
                  .isCertificateRenewable
              )
                this.renewableApplicationEmitter.emit(true);

              const certificateIssuedDate = environment.production
                ? Formatter.dateFormatConverter(
                    this.requestData.workFlowTrainingOrganization
                      ?.certificateIssuedDate,
                  )
                : new Date(
                    this.requestData.workFlowTrainingOrganization?.certificateIssuedDate,
                  );
              const certificateExpiryDate = environment['isDevelopment']
                ? new Date(
                    this.requestData.workFlowTrainingOrganization?.certificateExpiryDate,
                  )
                : Formatter.dateFormatConverter(
                    this.requestData.workFlowTrainingOrganization
                      ?.certificateExpiryDate,
                  );

              this.isCertificateDownloadable =
                new Date(Date.now()) >= certificateIssuedDate;

              if (this.isCertificateDownloadable) {
                this.activeTabIndex = this.tabs.length;
                this.tabs.push({
                  label: 'certificate',
                  content: 'certificate tab',
                });
              }

              this.certificateData = {
                programName: this.requestData?.trainingProgram?.nameAr,
                startDate: environment['isDevelopment']
                  ? certificateIssuedDate
                  : Formatter.dateToString(certificateIssuedDate),
                endDate: environment['isDevelopment']
                  ? certificateExpiryDate
                  : Formatter.dateToString(certificateExpiryDate),
                referenceId: this.requestData?.trainingOrganization?.reportId,
                trainingOrganizationId: this.id,
                organizationName:
                  this.requestData?.trainingOrganization?.organizationName,
                isTrainingOrg: true,
              };
            }

            if (
              this.requestStatus ===
              Status.RejectedByCommitteeSecretaryToTrainingOrganization
            )
              this.isRejectedApplication = true;

            this.reportIdEmitter.emit(
              this.requestData.trainingOrganization.reportId,
            );

            this.initializeForm();
            this.fetchAndAddDynamicFileFields();
            this.loadDropdownOptions();
            this.subscribeToFormChanges();

            if (
              this.requestData?.trainingOrganization?.isSubmitted &&
              !this.isEditableApplication
            ) {
              this.accreditationForm.disable();
              this.isReadOnly = true;
            }

            this.loadingService.stopLoading();
          }
        });
      } else {
        this.initializeForm();
        this.fetchAndAddDynamicFileFields();
        this.loadDropdownOptions();
        this.subscribeToFormChanges();
      }
    });
  }

  loadDropdownOptions() {
    this.loadCitiesOptions();
    this.loadRequestTypeOptions();
    this.loadProgramLevelOptions();
    this.loadProgramTypeOptions();
    this.loadLanguageOPtions();
  }

  loadCitiesOptions() {
    this.dropdownsOptionsService
      .getOptions(LookupsTypeEnum.CitiesEnum)
      .subscribe((res) => {
        this.cities = res.data;
      });
  }

  loadRequestTypeOptions() {
    this.dropdownsOptionsService
      .getOptions(LookupsTypeEnum.RequestTypeEnum)
      .subscribe((res) => {
        this.accreditationApplicationTypes = res.data;
      });
  }

  loadProgramLevelOptions() {
    this.dropdownsOptionsService
      .getOptions(LookupsTypeEnum.ProgramLevelEnum)
      .subscribe((res) => {
        this.trainingProgramLevels = res.data;
      });
  }

  loadProgramTypeOptions() {
    this.dropdownsOptionsService
      .getOptions(LookupsTypeEnum.ProgramTypeEnum)
      .subscribe((res) => {
        this.trainingProgramType = res.data;
      });
  }

  loadLanguageOPtions() {
    this.dropdownsOptionsService
      .getOptions(LookupsTypeEnum.LanguageEnum)
      .subscribe((res) => {
        this.trainingProgramLanguages = res.data;
      });
  }

  getValueByKey(types: any, key: number) {
    return types.find((type) => {
      return type?.key == key;
    })?.value;
  }

  get isTrainingOrgSaveButtonDisabled() {
    return (
      this.accreditationForm?.get('agencyName')?.invalid ||
      this.accreditationForm?.get('applicationType')?.invalid ||
      this.accreditationForm?.get('licenseDate')?.invalid ||
      this.accreditationForm?.get('city')?.invalid ||
      this.accreditationForm?.get('mobileNumber')?.invalid ||
      this.accreditationForm?.get('licenseExpiryDate')?.invalid ||
      this.accreditationForm?.get('address')?.invalid ||
      this.accreditationForm?.get('email')?.invalid ||
      this.accreditationForm?.get('licenseNumber')?.invalid ||
      this.accreditationForm?.get('cordinatorName')?.invalid
    );
  }

  resetTrainingOrgDataTab() {
    this.edittingTrainingOrgTab = false;
    this.accreditationForm.patchValue({
      agencyName: this.requestData.trainingOrganization.organizationName,
      applicationType: this.requestData.trainingOrganization.requestTypeId,
      licenseNumber:
        this.requestData.trainingOrganization.trainingLicenseNumber,
      licenseDate: this.requestData.trainingOrganization.licenseDate,
      licenseExpiryDate:
        this.requestData.trainingOrganization.licenseExpirationDate,
      city: this.requestData.trainingOrganization.cityId,
      address: this.requestData.trainingOrganization.address,
      email: this.requestData.trainingOrganization.email,
      mobileNumber: this.requestData.trainingOrganization.phoneNumber,
      cordinatorName: this.requestData.trainingOrganization.coordinatorName,
    });
  }

  get isTrainingProgramSaveButtonDisabled() {
    return (
      this.accreditationForm?.get('trainingProgramName')?.invalid ||
      this.accreditationForm?.get('trainingProgramEnglishName')?.invalid ||
      this.accreditationForm?.get('trainingProgramType')?.invalid ||
      this.accreditationForm?.get('trainingProgramLevel')?.invalid ||
      this.accreditationForm?.get('trainingProgramDays')?.invalid ||
      this.accreditationForm?.get('trainingProgramHours')?.invalid ||
      this.accreditationForm?.get('trainingProgramPurpose')?.invalid ||
      this.accreditationForm?.get('trainingProgramLanguage')?.invalid ||
      this.accreditationForm?.get('targetedCategory')?.invalid ||
      this.accreditationForm?.get('programDescription')?.invalid
    );
  }

  resetTrainingProgramDataTab() {
    this.edittingTrainingProgramTab = false;
    this.accreditationForm.patchValue({
      trainingProgramName: this.requestData.trainingProgram.nameAr,
      trainingProgramEnglishName: this.requestData.trainingProgram.nameEn,
      trainingProgramType:
        this.requestData?.trainingProgram?.trainingProgramTypeId,
      trainingProgramLevel:
        this.requestData?.trainingProgram?.programLevelLookupId,
      trainingProgramDays:
        this.requestData?.trainingProgram?.trainingProgramDuration,
      trainingProgramHours:
        this.requestData?.trainingProgram?.totalTrainingHours,
      trainingProgramPurpose:
        this.requestData?.trainingProgram?.programObjective,
      trainingProgramLanguage:
        this.requestData?.trainingProgram?.languageLookupId,
      targetedCategory: this.requestData?.trainingProgram?.targetCategory,
      programDescription: this.requestData?.trainingProgram?.description,
    });
  }

  checkProgramDocumentsValidationError(errorObject: { [id: string]: boolean }) {
    this.programDocumentsValidationErrors = {
      ...this.programDocumentsValidationErrors,
      ...errorObject,
    };
    this.isProgramDocumentsSaveButtonDisabled = Object.values(
      this.programDocumentsValidationErrors,
    ).includes(true);
  }

  resetTrainingDocumentsTab() {
    this.edittingTrainingDocumentsTab = false;
    this.requiredDocuments.forEach((document) => {
      const fieldName = `fileField_${document['programDocuments']?.key}`;
      if (this.accreditationForm.contains(fieldName)) {
        this.accreditationForm.removeControl(fieldName);
      }
    });
    this.programDocumentsValidationErrors = {};
    this.isProgramDocumentsSaveButtonDisabled = false;

    this.addDynamicFileFields();
  }

  get isGeneralCommitmentSaveButtonDisabled() {
    return (
      this.accreditationForm?.get('applicantName')?.invalid ||
      this.accreditationForm?.get('pledge')?.invalid
    );
  }

  resetGeneralCommitmentTab() {
    this.edittingGeneralCommitmentTab = false;
    this.accreditationForm.patchValue({
      applicantName: this.requestData.generalCommitment.applicantsName,
      signature:
        this.requestData?.generalCommitment?.generalCommitmentFile?.file?.id ||
        '',
      pledge: this.requestData?.trainingOrganization?.isSubmitted,
    });
  }

  viewFile(activeTabIndex: number, index: number): void {
    const archivedFiles =
      this.documentInspectorStateService.trainingProgramDocumentMapping[
        activeTabIndex - 1
      ]?.files[index]?.archivedFiles;

    if (
      !this.readOnly ||
      !archivedFiles ||
      this.requestStatus !==
        Status.ReturnedByInternalAuditorToTrainingOrganization
    ) {
      return;
    }

    this.bodyScrollService.toggleBodyScroll(false);
    this.documentInspectorStateService.setTabDataIndex(activeTabIndex - 1);
    this.fileIndex = index;
    this.allNotes =
      this.documentInspectorStateService?.trainingProgramDocumentMapping?.[
        activeTabIndex - 1
      ]?.programDocumentLookup?.programDocumentCriteriaMappings;

    this.inspectorComponent.fileIndex = index;
    this.isInspectorModalOpen = true;
    this.inspectorComponent.oldFileUrl = '';
    this.inspectorComponent.fileURL = '';

    const lastArchivedFile = archivedFiles[archivedFiles.length - 1];

    if (archivedFiles.length === 1) {
      this.inspectorComponent.getFileUrl(activeTabIndex - 1, index);
    } else if (this.updateRequestNumber > lastArchivedFile.fileVersion) {
      this.inspectorComponent.getFileUrl(activeTabIndex - 1, index);
    } else if (this.updateRequestNumber == lastArchivedFile.fileVersion) {
      this.inspectorComponent.getOldFileUrl(activeTabIndex - 1, index, true);
      this.inspectorComponent.getFileUrl(activeTabIndex - 1, index, true);
    }
  }

  hideModal() {
    this.isInspectorModalOpen = false;
    this.bodyScrollService.toggleBodyScroll(true);
  }

  setActiveTab(index: number) {
    this.activeTabIndex = index;
  }

  onTabDataIndexValueChange(newValue: any) {
    this.allNotes =
      this.documentInspectorStateService.trainingProgramDocumentMapping[
        newValue
      ].programDocumentLookup?.programDocumentCriteriaMappings;

    const tabName =
      this.documentInspectorStateService.trainingProgramDocumentMapping[
        newValue
      ]?.programDocumentLookup?.name;

    if (tabName == 'بيانات البرنامج التدريبي')
      this.inspectorComponent.showTrainingData;
    if (tabName == 'التعهد العام') this.inspectorComponent.showPledge = true;
  }

  onShowTrainingDataChange(showTrainingData: boolean) {
    this.showTrainingData = showTrainingData;
  }

  onShowPledge(showPledge: boolean) {
    this.showPledge = showPledge;
  }

  get rejectionReasonNote() {
    return this.requestData.trainingProgramDocumentNote.find(
      (note) =>
        note.documentNoteTypeLookupId === DocumentNoteType.ReasonsOfRejection,
    )?.note;
  }

  hideRejectionLayout() {
    this.showRejectionEmitter.emit(false);
    this.scrollIntoView();
  }

  submitButtonTextHandler() {
    switch (true) {
      case this.isEditableApplication:
        return 'إعادة تقديم الطلب';

      case this.isRenewalApplication:
        return 'تقديم طلب تجديد';

      case this.showSendInvoiceButton:
        return 'إرسال الإيصال';

      default:
        return 'تقديم الطلب';
    }
  }

  submitButtonClickHandler() {
    switch (true) {
      case !!this.accreditationForm.get('fieldVisitRequiredChangesDone'):
        this.isDialogOpen = true;
        break;

      case this.showSendInvoiceButton:
        this.paymentComponent.createOrUpdateInvoice();
        break;

      default:
        this.onSubmit({ asDraft: false });
        break;
    }
  }
}

export interface FileInputSetting {
  id: string;
  fileList: File[];
  text: string;
  data: string[];
}
