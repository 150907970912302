import { Component, Input, OnInit } from '@angular/core';
import { Status } from '../../types/status';
import { ArabicNumbersService } from 'src/app/services/arabic-numbers.service';
import { AuthService } from 'src/app/services/auth.service';
import { UserRole } from '@app/types/roles';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-field-visit-wrapper',
  templateUrl: './field-visit-wrapper.component.html',
  styleUrl: './field-visit-wrapper.component.scss',
})
export class FieldVisitWrapperComponent implements OnInit {
  @Input() fieldVisitData: Array<any> = [];
  @Input() requestStatus: number;
  @Input() accreditationForm: FormGroup;

  isNotAccordionView: boolean = false;
  isEditView: boolean = false;
  unClickable: boolean = false;
  isUserTrainingOrg: boolean = this.authService.hasRole(
    UserRole.TrainingOrganization,
  );

  constructor(
    private authService: AuthService,
    private arabicNumbersService: ArabicNumbersService,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    if (this.authService.hasRole(UserRole.InternalAuditor))
      this.isNotAccordionView =
        this.requestStatus ===
          Status.ReturnedByTechnicalAdvisorToInternalAuditor ||
        this.requestStatus ===
          Status.ReturnedByCommitteeSecrataryAfterTechnicalAdvisor;
    if (this.authService.hasRole(UserRole.CommitteeSecretary))
      this.isNotAccordionView =
        this.requestStatus ===
        Status.ApprovedByTechnicalExpertWaitingCommitteeSecretary;

    if (
      this.authService.hasRole(UserRole.InternalAuditor) ||
      this.authService.hasRole(UserRole.CommitteeSecretary)
    ) {
      this.unClickable = true;
    }

    if (
      this.fieldVisitData.length &&
      this.requestStatus ===
        Status.ReturnedByInternalAuditorToTrainingOrganization
    ) {
      const isFieldVisitReviewedByTrainingOrg = this.fieldVisitData
        .at(-1)
        .fieldVisitForms.some((std) =>
          std.fieldVisitFormMappingFiles.some(
            (file) => file?.user.roleId === UserRole.TrainingOrganization,
          ),
        );

      if (!isFieldVisitReviewedByTrainingOrg) {
        this.isNotAccordionView = true;
        this.isEditView = true;
        const standardsControls = this.fb.array([]);
        this.fieldVisitData
          .at(-1)
          .fieldVisitForms.forEach((std) =>
            standardsControls.push(
              std.isApplied
                ? this.fb.control(true)
                : this.fb.control('', Validators.required),
            ),
          );
        this.accreditationForm.addControl(
          'fieldVisitStandardsFiles',
          standardsControls,
        );

        this.accreditationForm.addControl(
          'fieldVisitRequiredChangesDone',
          this.fb.control('', Validators.requiredTrue),
        );
      } else if (this.authService.hasRole(UserRole.TrainingOrganization))
        this.isNotAccordionView = false;
    }
  }

  get filesControlsArray() {
    return this.accreditationForm?.get('fieldVisitStandardsFiles') as FormArray;
  }

  getNumbersInArabicText(number: number) {
    return this.arabicNumbersService.getNumbersInArabicText(number);
  }

  getHeaderText(index: number): string {
    return `تقييم الزيارة الميدانية (الزيارة ${this.getNumbersInArabicText(
      index + 1,
    )})`;
  }
}
