<div class="banner-container">
  <div class="welcome-text__container">
    <span class="welcome">مرحبا بك في </span>
    <h1 class="welcome-text">لوحة التحكم الرئيسية</h1>
  </div>
  <img [src]="banner" alt="banner" class="banner-image" />
</div>
<div class="container">
  <div>
    <p-tabView [activeIndex]="activeTabIndex" (onChange)="onTabChange($event)">
      <loading-spinner />

      <p-tabPanel header="الطلبات للبرامج التي قامت الجهات التدريبية بتأليفها">
        <div class="subject">
          <span>قياس المؤشرات</span>
          <div>
            <app-chart
              [chartType]="chartTypeActiveUsers"
              [chartData]="annualReport"
              [currentYear]="activeUsersReportYear"
              [chartTitle]="activeUsersReportLabel"
              (previousYear)="previousYear(1)"
              (nextYear)="nextYear(1)"
            >
            </app-chart>
          </div>
          <div>
            <app-chart
              [chartType]="chartTypeCompletionRate"
              [chartData]="applicationCompleationRate"
              [currentYear]="ApplicationCompleationRateYear"
              [chartTitle]="applicationCompleationRateLabel"
              (previousYear)="previousYear(2)"
              (nextYear)="nextYear(2)"
            >
            </app-chart>
          </div>

          <div class="table-data">
            <div *ngIf="DurationOfAccredition">
              <app-reports-table
                [data]="DurationOfAccredition.data"
                [title]="DurationOfAccredition.title"
                [headers]="DurationOfAccredition.headers"
              >
              </app-reports-table>
            </div>
            <div *ngIf="DurationOfSubmission">
              <app-reports-table
                [data]="DurationOfSubmission.data"
                [title]="DurationOfSubmission.title"
                [headers]="DurationOfSubmission.headers"
              >
              </app-reports-table>
            </div>
            <div *ngIf="numberOfRequestsTable">
              <app-reports-table
                [data]="numberOfRequestsTable.data"
                [title]="numberOfRequestsTable.title"
                [headers]="numberOfRequestsTable.headers"
              >
              </app-reports-table>
            </div>
          </div>
          <app-chart
          [chartType]="chartTypeWithdrawalRate"
          [chartData]="withdrawnApplicationRate"
          [currentYear]="withdrawnApplicationYear"
          [chartTitle]="withdrawnApplicationLabel"
          (previousYear)="previousYear(3)"
          (nextYear)="nextYear(3)"
        >
        </app-chart>
        </div>
      </p-tabPanel>
      <p-tabPanel header="الطلبات للبرامج التي عمل المركز على إعدادها">
        <div class="subject">
          <span>قياس المؤشرات</span>
          <app-chart
            [chartType]="chartTypeActiveUsers"
            [chartData]="annualReport"
            [currentYear]="activeUsersReportYear"
            [chartTitle]="activeUsersReportLabel"
            (previousYear)="previousYear(1)"
            (nextYear)="nextYear(1)"
          >
          </app-chart>
          <div>
            <app-chart
              [chartType]="chartTypeCompletionRate"
              [chartData]="applicationCompleationRate"
              [currentYear]="ApplicationCompleationRateYear"
              [chartTitle]="applicationCompleationRateLabel"
              (previousYear)="previousYear(2)"
              (nextYear)="nextYear(2)"
            >
            </app-chart>
          </div>
          <div class="table-data">
            <div *ngIf="DurationOfAccredition">
              <app-reports-table
                [data]="DurationOfAccredition.data"
                [title]="DurationOfAccredition.title"
                [headers]="DurationOfAccredition.headers"
              >
              </app-reports-table>
            </div>

            <div *ngIf="DurationOfSubmission">
              <app-reports-table
                [data]="DurationOfSubmission.data"
                [title]="DurationOfSubmission.title"
                [headers]="DurationOfSubmission.headers"
              >
              </app-reports-table>
            </div>
           
            <div *ngIf="numberOfRequestsTable">
              <app-reports-table
                [data]="numberOfRequestsTable.data"
                [title]="numberOfRequestsTable.title"
                [headers]="numberOfRequestsTable.headers"
              >
              </app-reports-table>
            </div>
          </div>
          <app-chart
          [chartType]="chartTypeWithdrawalRate"
          [chartData]="withdrawnApplicationRate"
          [currentYear]="withdrawnApplicationYear"
          [chartTitle]="withdrawnApplicationLabel"
          (previousYear)="previousYear(3)"
          (nextYear)="nextYear(3)"
        >
        </app-chart>
        </div
      ></p-tabPanel>
    </p-tabView>
  </div>
</div>
