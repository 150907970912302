import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserRole } from 'src/app/types/roles';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  activeRoute: string = '';
  userRole: UserRole = UserRole.TrainingOrganization;
  currentUrl: string = '';

  userItems = [
    {
      svg: 'home-icon',
      text: 'home',
      route: '/home',
    },
    {
      svg: 'squares-icon',
      text: 'dashboard',
      route: '/dashboard',
    },
    {
      svg: 'review-icon',
      text: 'مراجعة إيصالات السداد',
      route: '/dashboard/receipts',
      roles: [UserRole.CommitteeSecretary],

      condition: true,
    },
    {
      svg: 'waiting-vote-icon',
      text: 'طلبات في انتظار التصويت',
      route: '/dashboard/my-applications',
      roles: [UserRole.CommitteeSecretary],
      condition: false,
    },
  ];

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private authService: AuthService, // Add AuthService dependency
  ) {}

  ngOnInit() {
    this.currentUrl = this.router.url;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.urlAfterRedirects;
      }
    });
  }

  getFilteredItems(): any[] {
    const userRolesSet = new Set([this.authService.getUserRole()]);
    return this.userItems.filter((item) => {
      if (item.roles) {
        const rolesCondition = item.roles.some((role) =>
          userRolesSet.has(role),
        );
        const additionalCondition = item.condition === true;

        return rolesCondition && additionalCondition;
      }

      return true;
    });
  }

  isActive(item: any): boolean {
    const currentUrl = this.currentUrl.split('?')[0].split('#')[0];

    if (currentUrl === item.route) {
      return true;
    }

    if (item.route === '/dashboard') {
      const specificRoutes = this.userItems
        .filter((i) => i.route !== '/dashboard')
        .map((i) => i.route);

      const isSpecificRoute = specificRoutes.some((route) =>
        currentUrl.startsWith(route),
      );
      return !isSpecificRoute;
    }

    return false;
  }
}
