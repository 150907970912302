import { Component, OnInit } from '@angular/core';
import { ChartDataService } from '@app/dashboard/services/chart-data.service';
import {
  TableService,
  TableData,
} from '@app/dashboard/services/table-data.service';
import { Assets } from '@assets/images';
import { ReportCategory } from 'src/app/types/chart-reports';
@Component({
  selector: 'app-quarter-reports',
  templateUrl: './quarter-reports.component.html',
  styleUrl: './quarter-reports.component.scss',
})
export class QuarterReportsComponent implements OnInit {
  banner = Assets.banner;
  activeTabIndex: number = 0;
  reportCategory = ReportCategory;
  chartType = 'bar';

  annualReportYear: number = new Date().getFullYear();
  semiAnnualReportYear: number = new Date().getFullYear();
  quarterReportYear: number = new Date().getFullYear();

  annualReportLabel = 'عدد الاعتمادات السنوي';
  semiAnnualReportLabel = 'عدد الاعتمادات النصفي';
  quarterReportLabel = 'عدد الاعتمادات الربعي';

  annualReport: any;
  semiAnnualReport: any;
  quarterReport: any;

  numberOfRequestsTable: TableData | null = null;
  averageTimeTable: TableData | null = null;

  constructor(
    private chartDataService: ChartDataService,
    private tableService: TableService,
  ) {}

  ngOnInit(): void {
    this.loadData(this.mapAccreditationRequestType(this.activeTabIndex));
  }

  onTabChange(event: any) {
    this.activeTabIndex = event.index;
    this.loadData(this.mapAccreditationRequestType(this.activeTabIndex));
  }

  mapAccreditationRequestType(tabIndex: number): number {
    return tabIndex === 0 ? 2 : 1;
  }

  loadData(accreditationRequestType: number) {
    this.loadAnnualReport(accreditationRequestType);
    this.loadSemiAnnualReport(accreditationRequestType);
    this.loadQuarterReport(accreditationRequestType);
  }

  loadAnnualReport(accreditationRequestType: number) {
    this.chartDataService
      .getChartData(this.annualReportYear, 1, accreditationRequestType)
      .subscribe((data) => {
        this.annualReport = data.chart;
      });
  }

  loadSemiAnnualReport(accreditationRequestType: number) {
    this.chartDataService
      .getChartData(this.semiAnnualReportYear, 2, accreditationRequestType)
      .subscribe((data) => {
        this.semiAnnualReport = data.chart;
      });
  }

  loadQuarterReport(accreditationRequestType: number) {
    this.chartDataService
      .getChartData(this.quarterReportYear, 3, accreditationRequestType)
      .subscribe((data) => {
        this.quarterReport = data.chart;
      });
  }

  nextYear(chart: number): void {
    if (chart === ReportCategory.AnnualReport) {
      this.annualReportYear++;
      this.loadAnnualReport(
        this.mapAccreditationRequestType(this.activeTabIndex),
      );
    } else if (chart === ReportCategory.SemiAnnualReport) {
      this.semiAnnualReportYear++;
      this.loadSemiAnnualReport(
        this.mapAccreditationRequestType(this.activeTabIndex),
      );
    } else if (chart === ReportCategory.QuarterReport) {
      this.quarterReportYear++;
      this.loadQuarterReport(
        this.mapAccreditationRequestType(this.activeTabIndex),
      );
    }
  }

  previousYear(chart: number): void {
    if (chart === ReportCategory.AnnualReport) {
      this.annualReportYear--;
      this.loadAnnualReport(
        this.mapAccreditationRequestType(this.activeTabIndex),
      );
    } else if (chart === ReportCategory.SemiAnnualReport) {
      this.semiAnnualReportYear--;
      this.loadSemiAnnualReport(
        this.mapAccreditationRequestType(this.activeTabIndex),
      );
    } else if (chart === ReportCategory.QuarterReport) {
      this.quarterReportYear--;
      this.loadQuarterReport(
        this.mapAccreditationRequestType(this.activeTabIndex),
      );
    }
  }
}
