import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ReportCategory } from 'src/app/types/chart-reports';

@Injectable({
  providedIn: 'root',
})
export class ChartDataService {
  private apiUrl = environment.apiUrl;

  // Labels for annual and semi-annual reports
  private annualLabels: string[] = [
    'يناير',
    'فبراير',
    'مارس',
    'إبريل',
    'مايو',
    'يونيو',
    'يوليو',
    'أغسطس',
    'سبتمبر',
    'أكتوبر',
    'نوفمبر',
    'ديسمبر',
  ];

  private semiAnnualLabels: string[] = [
    'النصف الاول ( يناير ـ يونيو)',
    'النصف الثاني ( يوليو ـ ديسمبر)',
  ];

  private quarterlLabels: string[] = [
    'الربع الأول',
    'الربع الثاني',
    'الربع الثالث',
    'الربع الرابع',
  ];

  constructor(private http: HttpClient) {}

  // Fetch data based on year and report period (1 for annual, 2 for semi-annual)
  getChartData(
    year: number,
    reportPeriod: number,
    accreditationRequestType: number,
  ): Observable<{ chart: any }> {
    return this.http
      .get<any>(
        `${this.apiUrl}/api/SystemAdministrator/GetQuarterlyAnalysisReport?accreditationRequestType=${accreditationRequestType}&year=${year}&reportPeriod=${reportPeriod}`,
      )
      .pipe(
        map((response) => {
          const labels =
            reportPeriod === ReportCategory.AnnualReport
              ? this.annualLabels
              : reportPeriod === ReportCategory.SemiAnnualReport
                ? this.semiAnnualLabels
                : this.quarterlLabels;

          let datasetLabel = '';
          switch (reportPeriod) {
            case ReportCategory.AnnualReport:
              datasetLabel = 'عدد الاعتمادات السنوي';
              break;
            case ReportCategory.SemiAnnualReport:
              datasetLabel = 'عدد الاعتمادات النصفي';
              break;
            case ReportCategory.QuarterReport:
              datasetLabel = 'عدد الاعتمادات الربعي';
              break;
          }

          const datasets = [
            {
              pointStyle: 'circle',
              label: datasetLabel,
              backgroundColor: '#336D56',
              data: response.data.reportData.map(
                (item: any) => item.totalAccreditations,
              ),
            },
          ];

          return {
            chart: {
              labels,
              datasets,
            },
          };
        }),
      );
  }
}
