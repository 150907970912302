<div class="container">
  <div class="banner-container">
    <div class="welcome-text__container">
      <span class="welcome">مرحبا بك في </span>
      <h1 class="welcome-text">
        اعتماد البرامج التدريبية في مجال كفاءة الطاقة
      </h1>
    </div>
    <img src="assets/images/banner.png" alt="banner" class="banner-image" />
  </div>

  <loading-spinner></loading-spinner>
  <div class="status-tracker">
    <div class="header-label">
      <label>تتبع حالة طلب الاعتماد :</label>
    </div>
    <div class="timeline">
      <div class="timeline-step" *ngFor="let step of steps">
        <div class="icon">
          <img
            *ngIf="step.icon.endsWith('.svg')"
            [src]="step.icon"
            alt="Step icon"
          />
          <span *ngIf="!step.icon.endsWith('.svg')">{{ step.icon }}</span>
        </div>
        <div class="details">
          <h3>{{ step.stage }}</h3>
          <p>{{ step.date }}</p>
          <ul>
            <li *ngFor="let action of step.actions">
              <div class="action-icon">
                <div class="sub-icons">
                  <img
                    *ngIf="action.icon.endsWith('.svg')"
                    [src]="action.icon"
                    alt="Action icon"
                  />
                </div>
              </div>
              <span>{{ action.description }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
