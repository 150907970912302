<div class="sidebar-container">
  <div class="navigation-items-container">
    <a class="logo" routerLink="/">
      <img class="logo-svg" src="assets/images/logo.svg" alt="" />
    </a>
  </div>

  <div class="sidebar-items__container">
    <div *ngFor="let item of getFilteredItems()">
      <a
        class="sidebar-item"
        [routerLink]="item.route"
        (click)="selectSidebarItem(item.route)"
        [ngClass]="{ active: activeRoute === item.route }"
        *ngIf="!item.children"
      >
        <app-svg-icon class="side-bar-icon" [name]="item.svg"></app-svg-icon>
        <span class="sidebar-item__text">{{ item.text | translate }}</span>
      </a>

      <ng-container *ngIf="item.children">
        <div
          class="sidebar-item expandable-item"
          (click)="toggleItem(item)"
          [ngClass]="{ active: isParentActive(item) }"
        >
        <div class="item-content">
          <app-svg-icon class="side-bar-icon" [name]="item.svg"></app-svg-icon>
          <span class="sidebar-item__text">{{ item.text | translate }}</span>
        </div>
          <app-svg-icon
          class="side-bar-icon"
            [name]="item.expanded ? 'arrow-down-2' : 'arrow-up'"
          ></app-svg-icon>
        </div>
        

        <div *ngIf="item.expanded" class="child-items">
          <a
            class="child-item"
            *ngFor="let child of item.children"
            [routerLink]="child.route"
            (click)="selectSidebarItem(child.route)"
            [ngClass]="{ active: activeRoute === child.route }"
          >
            <span class="child-item__text">{{ child.text | translate }}</span>
          </a>
        </div>
      </ng-container>
    </div>
  </div>
</div>
