import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';
import { AuditService } from '@app/dashboard/services/audit.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'receipts-table',
  templateUrl: './receipts-table.component.html',
  styleUrl: './receipts-table.component.scss',
})
export class ReceiptsTableComponent {
  headers: any[] = [
    { name: 'رقم الطلب', value: 'reportId' },
    { name: 'تاريخ الإيصال', value: 'creationDate' },
    { name: 'حالة الإيصال', value: 'trainingOrganizationStatusStr' },
  ];

  status: any[] = [{ key: 11, value: 'مكتمل' }];
  tableData: any;
  showModal: boolean = false;
  modifiedTableData: any[];
  applicaionIdQueryParam: string = '';
  isHighlighted: boolean = false;

  constructor(
    private auditService: AuditService,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
  ) {}

  isLoading: Observable<boolean> = this.loadingService.isLoading$();

  ngOnInit(): void {
    this.applicaionIdQueryParam = this.getQueryParam('applicationId');
    this.loadingService.startLoading();
    this.getTableData();
  }

  getQueryParam(param: string): string {
    return this.route.snapshot.queryParamMap.get(param);
  }

  getFilterData(lookup: number, filterValue: string[]) {
    this.auditService.getFiltersData(lookup).subscribe({
      next: (data) => {
        filterValue.length = 0;
        Array.prototype.push.apply(filterValue, data.data);
      },
      error: (error) => {
        console.error('Error fetching data:', error);
      },
    });
  }

  getTableData() {
    const body = {
      pageCount: 1,
      pageSize: 10,
      reportId: '',
      allowSearch: false,
      applicationId: this.applicaionIdQueryParam,
      // organizationName: '',
      // fromDate: '',
      // toDate: '',
      // languageTypeId: '',
      trainingOrganizationStatusId: 9,
    };

    this.auditService.getReceiptsList(body).subscribe({
      next: (data) => {
        this.loadingService.stopLoading();
        this.tableData = data.data;
        this.isHighlighted = this.tableData.data.some(
          (item) => item.trainingOrganizationId == this.applicaionIdQueryParam,
        );
      },
      error: (error) => {
        console.log('error', error);
      },
    });
  }

  sendToGeneralManagerHandler() {
    this.showModal = true;
  }
}
