import { Component, OnInit } from '@angular/core';
import { ChartDataService } from '@app/dashboard/services/chart-data.service';
import {
  TableService,
  TableData,
} from '@app/dashboard/services/table-data.service';
import { Assets } from '@assets/images';
import { ReportCategory } from 'src/app/types/chart-reports';

@Component({
  selector: 'app-overall-reports',
  templateUrl: './overall-reports.component.html',
  styleUrls: ['./overall-reports.component.scss'],
})
export class OverallReportsComponent implements OnInit {
  banner = Assets.banner;
  activeTabIndex: number = 0;
  chartType = 'bar';
  reportCategory = ReportCategory;

  annualReportYear: number = new Date().getFullYear();
  semiAnnualReportYear: number = new Date().getFullYear();
  quarterReportYear: number = new Date().getFullYear();

  annualReportLabel =
    'عدد الطلبات السنوي للبرامج التدريبية التي قامت الجهات التدريبية بتأليفها';
  semiAnnualReportLabel =
    'عدد الاعتمادات النصفي للبرامج التدريبية التي قامت الجهات التدريبية بتأليفها';
  quarterReportLabel =
    'عدد الاعتمادات الربعي للبرامج التدريبية التي قامت الجهات التدريبية بتأليفها';

  annualReport: any;
  semiAnnualReport: any;
  quarterReport: any;

  numberOfRequestsTable: TableData | null = null;
  averageTimeTable: TableData | null = null;

  constructor(
    private chartDataService: ChartDataService,
    private tableService: TableService,
  ) {}

  ngOnInit(): void {
    this.loadData(this.mapAccreditationRequestType(this.activeTabIndex));
  }

  onTabChange(event: any) {
    this.activeTabIndex = event.index;
    this.loadData(this.mapAccreditationRequestType(this.activeTabIndex));
  }

  mapAccreditationRequestType(tabIndex: number): number {
    return tabIndex === 0 ? 2 : 1;
  }

  loadData(accreditationRequestType: number) {
    this.clearChartData();
    this.loadAnnualReport(accreditationRequestType);
    this.loadSemiAnnualReport(accreditationRequestType);
    this.loadQuarterReport(accreditationRequestType);

    // Load table data
    if (this.activeTabIndex === 0) {
      this.tableService
        .getAverageTimeDataByOrg(accreditationRequestType)
        .subscribe((data) => {
          this.averageTimeTable = data;
        });
    } else if (this.activeTabIndex === 1) {
      this.tableService
        .getAverageTimeDataByCenter(accreditationRequestType)
        .subscribe((data) => {
          this.averageTimeTable = data;
        });
    }
  }

  loadAnnualReport(accreditationRequestType: number) {
    this.chartDataService
      .getChartData(this.annualReportYear, 1, accreditationRequestType)
      .subscribe((data) => {
        this.annualReport = data.chart;
      });
  }

  loadSemiAnnualReport(accreditationRequestType: number) {
    this.chartDataService
      .getChartData(this.semiAnnualReportYear, 2, accreditationRequestType)
      .subscribe((data) => {
        this.semiAnnualReport = data.chart;
      });
  }

  loadQuarterReport(accreditationRequestType: number) {
    this.chartDataService
      .getChartData(this.quarterReportYear, 3, accreditationRequestType)
      .subscribe((data) => {
        this.quarterReport = data.chart;
      });
  }
  clearChartData() {
    this.annualReport = null;
    this.semiAnnualReport = null;
    this.quarterReport = null;
  }

  nextYear(chart: number): void {
    if (chart === ReportCategory.AnnualReport) {
      this.annualReportYear++;
      this.loadAnnualReport(
        this.mapAccreditationRequestType(this.activeTabIndex),
      );
    } else if (chart === ReportCategory.SemiAnnualReport) {
      this.semiAnnualReportYear++;
      this.loadSemiAnnualReport(
        this.mapAccreditationRequestType(this.activeTabIndex),
      );
    } else if (chart === ReportCategory.QuarterReport) {
      this.quarterReportYear++;
      this.loadQuarterReport(
        this.mapAccreditationRequestType(this.activeTabIndex),
      );
    }
  }

  previousYear(chart: number): void {
    if (chart === ReportCategory.AnnualReport) {
      this.annualReportYear--;
      this.loadAnnualReport(
        this.mapAccreditationRequestType(this.activeTabIndex),
      );
    } else if (chart === ReportCategory.SemiAnnualReport) {
      this.semiAnnualReportYear--;
      this.loadSemiAnnualReport(
        this.mapAccreditationRequestType(this.activeTabIndex),
      );
    } else if (chart === ReportCategory.QuarterReport) {
      this.quarterReportYear--;
      this.loadQuarterReport(
        this.mapAccreditationRequestType(this.activeTabIndex),
      );
    }
  }
}
