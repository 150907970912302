import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';

interface DataItem {
  status?: string;
  count?: number;
  typeOfRequest?: string;
  numberOfDays?: number;
}

interface HeaderColumn {
  field: string;
  header: string;
}

export interface TableData {
  title: string;
  data: DataItem[];
  headers: HeaderColumn[];
}

@Injectable({
  providedIn: 'root',
})
export class MeasuringIndicatorsService {
  private apiUrl = environment.apiUrl;

  // Labels for annual and semi-annual reports
  mounths: string[] = [
    'يناير',
    'فبراير',
    'مارس',
    'إبريل',
    'مايو',
    'يونيو',
    'يوليو',
    'أغسطس',
    'سبتمبر',
    'أكتوبر',
    'نوفمبر',
    'ديسمبر',
  ];

  private applicationCompleationLabels: string[] = [
    'الجهات التدريبية الذين أكملوا الطلب بنجاح',
    'الجهات التدريبية الذين بدأوا في تقديم الطلب و لم يكملوا بعد',
  ];

  private numberOfRequestsHeaders: HeaderColumn[] = [
    { field: 'status', header: ' حساب الوقت الذي يستغرقه تقديم الطلبات' },
    { field: 'count', header: ' المدة' },
  ];

  private averageTimeHeaders: HeaderColumn[] = [
    {
      field: 'status',
      header: 'حساب الوقت الذي تستغرقه الطلبات حتى الإعتماد ',
    },
    { field: 'numberOfDays', header: ' المدة' },
  ];

  constructor(private http: HttpClient) {}

  // Fetch data based on year and report period (1 for annual, 2 for semi-annual)
  getActiveUsersData(year: number): Observable<{ chart: any }> {
    return this.http
      .get<any>(
        `${this.apiUrl}/api/SystemAdministrator/GetTotalActiveInternalUsers?year=${year}`,
      )
      .pipe(
        map((response) => {
          const labels = this.mounths;
          const datasets = [
            {
              label: 'عدد المستخدمين النشطين',
              backgroundColor: '#336D56',
              data: response.data.map((item: any) => item.value),
            },
          ];

          return {
            chart: {
              labels,
              datasets,
            },
          };
        }),
      );
  }

  getApplicationCompletionRate(
    accreditationRequestType: number,
    year: number,
  ): Observable<{ chart: any }> {
    return this.http
      .get<any>(
        `${this.apiUrl}/api/SystemAdministrator/ApplicationsCompletionRate?accreditationRequestType=${accreditationRequestType}&year=${year}`,
      )
      .pipe(
        map((response) => {
          const labels = this.applicationCompleationLabels;
          const colors = ['#2C6E59', '#619C8A']; // Set colors for each slice
          const datasets = [
            {
              pointStyle: 'circle',
              backgroundColor: colors,
              data: Array.isArray(response.data)
                ? response.data.map((item: any) => item.value)
                : [response.data.submissionRate, response.data.draftRate],
            },
          ];

          return {
            chart: {
              labels,
              datasets,
            },
          };
        }),
      );
  }

  getDurationOfsubmitRequest(
    accreditationRequestType: number,
  ): Observable<TableData> {
    return this.http
      .get<any>(
        `${this.apiUrl}/api/SystemAdministrator/GetSubmissionAndAccreditationDuration?accreditationRequestType=${accreditationRequestType}`,
      )
      .pipe(
        map((response) => {
          const data: DataItem[] = [
            {
              status: ' الحد الأعلى للوقت الذي يستغرقه تقديم الطلبات',
              count: response.data.maxDurationOfAccreditionInDays,
            },
            {
              status: ' متوسط الوقت الذي يستغرقه لتقديم الطلبات',
              count: response.data.avgDurationOfAccreditionInDays,
            },
            {
              status: 'الحد الأدنى للوقت الذي يستغرقه تقديم الطلبات',
              count: response.data.minDurationOfAccreditionInDays,
            },
          ];
          return {
            title: ' وقت تقديم الطلبات',
            data,
            headers: this.numberOfRequestsHeaders,
          };
        }),
      );
  }

  // Fetch data for average processing time
  getAverageTimeData(accreditationRequestType: number): Observable<TableData> {
    return this.http
      .get<any>(
        `${this.apiUrl}/api/SystemAdministrator/GetSubmissionAndAccreditationDuration?accreditationRequestType=${accreditationRequestType}`,
      )
      .pipe(
        map((response) => {
          const data: DataItem[] = [
            {
              status: 'الحد الأعلى للوقت الذي تستغرقه الطلبات',
              numberOfDays: response.data.maxDurationOfSubmissionInDays,
            },
            {
              status: ' متوسط الوقت الذي تستغرقه الطلبات ',
              numberOfDays: response.data.avgDurationOfSubmissionsInDays,
            },
            {
              status: 'الحد الأدنى للوقت الذي تستغرقه الطلبات',
              numberOfDays: response.data.minDurationOfSubmissionInDays,
            },
          ];
          return {
            title: 'الوقت التي تستغرقه الطلبات',
            data,
            headers: this.averageTimeHeaders,
          };
        }),
      );
  }

  getWithDrawApplicationData(
    accreditationRequestType: number,
    year: number,
  ): Observable<{ chart: any }> {
    return this.http
      .get<any>(
        `${this.apiUrl}/api/SystemAdministrator/GetWithdrawnApplicationRate?accreditationRequestType=${accreditationRequestType}&year=${year}`,
      )
      .pipe(
        map((response) => {
          const labels = this.mounths;
          const withdrawlData = response.data.map(
            (item: any) => item.value.withdrawlApplications,
          );
          const submittedData = response.data.map(
            (item: any) => item.value.submittedApplications,
          );

          const datasets = [
            {
              pointStyle: 'circle',
              label: 'الجهات التدريبية المنسحبة ولم تكمل تقديم الطلب',
              backgroundColor: '#66A793',
              data: withdrawlData,
            },
            {
              pointStyle: 'circle',
              label: 'الجهات التدريبية التي اكملت تقديم الطلب',
              backgroundColor: '#2C6E59',
              data: submittedData,
            },
          ];

          return {
            chart: {
              labels,
              datasets,
            },
          };
        }),
      );
  }
}
