import { Component, OnInit } from '@angular/core';
import { Assets } from '@assets/images';
import { MeasuringIndicatorsService } from '@app/dashboard/services/measuring-indicators.service';
import {
  TableService,
  TableData,
} from '@app/dashboard/services/table-data.service';

@Component({
  selector: 'app-measuring-indicators',
  templateUrl: './measuring-indicators.component.html',
  styleUrl: './measuring-indicators.component.scss',
})
export class MeasuringIndicatorsComponent implements OnInit {
  banner = Assets.banner;
  activeTabIndex: number = 0;
  chartTypeActiveUsers = 'bar';
  chartTypeCompletionRate = 'pie';
  chartTypeWithdrawalRate = 'bar';

  activeUsersReportYear: number = new Date().getFullYear();
  ApplicationCompleationRateYear: number = new Date().getFullYear();
  withdrawnApplicationYear: number = new Date().getFullYear();

  activeUsersReportLabel = 'عدد المستخدمين النشطين';
  applicationCompleationRateLabel = 'نسبة إكمال الطلبات';
  withdrawnApplicationLabel = 'معدل انسحاب الجهات من التقديم';

  annualReport: any;
  applicationCompleationRate: any;
  withdrawnApplicationRate: any;

  numberOfRequestsTable: any;
  averageTimeTable: any;

  DurationOfAccredition: TableData | null = null;
  DurationOfSubmission: TableData | null = null;

  constructor(
    private MeasuringIndicatorsService: MeasuringIndicatorsService,
    private tableService: TableService,
  ) {}

  ngOnInit(): void {
    this.loadData(this.mapAccreditationRequestType(this.activeTabIndex));
  }

  onTabChange(event: any) {
    this.activeTabIndex = event.index;
    this.loadData(this.mapAccreditationRequestType(this.activeTabIndex));
  }

  mapAccreditationRequestType(tabIndex: number): number {
    return tabIndex === 0 ? 2 : 1;
  }

  loadData(accreditationRequestType?: number) {
    this.loadActiveUsersReport();
    this.loadApplicationCompleationRate(accreditationRequestType);
    this.loadWithdrawnApplicationRate(accreditationRequestType);

    // Load table data
    this.MeasuringIndicatorsService.getDurationOfsubmitRequest(
      accreditationRequestType,
    ).subscribe((data) => {
      this.DurationOfAccredition = data;
    });

    this.MeasuringIndicatorsService.getAverageTimeData(
      accreditationRequestType,
    ).subscribe((data) => {
      this.DurationOfSubmission = data;
    });

    this.tableService
      .getNumberOfRequestsData(accreditationRequestType)
      .subscribe((data) => {
        this.numberOfRequestsTable = data;
      });
  }

  loadActiveUsersReport() {
    this.MeasuringIndicatorsService.getActiveUsersData(
      this.activeUsersReportYear,
    ).subscribe((data) => {
      this.annualReport = data.chart;
    });
  }

  loadApplicationCompleationRate(accreditationRequestType: number) {
    this.MeasuringIndicatorsService.getApplicationCompletionRate(
      accreditationRequestType,
      this.ApplicationCompleationRateYear,
    ).subscribe((data) => {
      this.applicationCompleationRate = data.chart;
    });
  }

  loadWithdrawnApplicationRate(accreditationRequestType: number) {
    this.MeasuringIndicatorsService.getWithDrawApplicationData(
      accreditationRequestType,
      this.withdrawnApplicationYear,
    ).subscribe((data) => {
      this.withdrawnApplicationRate = data.chart;
    });
  }
  nextYear(chart: number): void {
    if (chart === 1) {
      this.activeUsersReportYear++;
      this.loadActiveUsersReport();
    } else if (chart === 2) {
      this.ApplicationCompleationRateYear++;
      this.loadApplicationCompleationRate(
        this.mapAccreditationRequestType(this.activeTabIndex),
      );
    } else if (chart === 3) {
      this.withdrawnApplicationYear++;
      this.loadWithdrawnApplicationRate(
        this.mapAccreditationRequestType(this.activeTabIndex),
      );
    }
  }

  previousYear(chart: number): void {
    if (chart === 1) {
      this.activeUsersReportYear--;
      this.loadActiveUsersReport();
    } else if (chart === 2) {
      this.ApplicationCompleationRateYear--;
      this.loadApplicationCompleationRate(
        this.mapAccreditationRequestType(this.activeTabIndex),
      );
    } else if (chart === 3) {
      this.withdrawnApplicationYear--;
      this.loadWithdrawnApplicationRate(
        this.mapAccreditationRequestType(this.activeTabIndex),
      );
    }
  }
}
