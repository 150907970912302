<div class="btn-container">
  <button pButton type="button" label="تصدير" (click)="downloadChart()" >
    <i class="pi pi-download"></i>
  </button>
</div>
<div class="table-container">
  <div class="header-section">
    <div class="year-selector">
      <button
        pButton
        type="button"
        icon="pi pi-chevron-left"
        (click)="onPreviousYear()"
      ></button>
      <span>{{ currentYear }}</span>
      <button
        pButton
        type="button"
        icon="pi pi-chevron-right"
        (click)="onNextYear()"
      ></button>
    </div>
    <h2 class="chart-title">{{ chartTitle }}</h2>
  </div>

  <p-chart
    #chartComponent
    type="{{ chartType }}"
    [data]="chartData"
    [options]="chartOptions"
    height="300px"
  ></p-chart>
</div>
