import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.development';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  initializeGTM() {
    const gtmId = environment.googleTagManagerId;
    const googleDomain = environment.googleDomain;

    // Initialize dataLayer if not already present
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    // GTM Script - dynamically create the loader function as in original code
    const script = document.createElement('script');
    script.innerHTML = `
      (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = '${googleDomain}/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${gtmId}');
    `;
    document.head.appendChild(script);

    // GTM NoScript
    const noscript = document.createElement('noscript');
    noscript.innerHTML = `<iframe src="${googleDomain}/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(noscript);
  }
}
